import mock from '../mock'
// NEED TO BE CHANGED!!!
const data = {
  accountSetting: {
    general: {
      avatar: require('@src/assets/images/portrait/small/avatar-s-11.jpg').default,
      username: 'johncoach',
      pronouns: 'His',
      firstName: 'Coach John',
      lastName: 'Doe',
      email: 'granger007@hogward.com',
      company: 'IBM Technology'
    },
    info: {
      bio: '',
      dob: null,
      city: 'Los Angeles',
      state: 'California',
      website: '',
      linkedIn: 'https://www.linkedin.com',
      certifications: 'iPEC, ILCT',
      instructionType: ['1 to 1'],
      modalities: ['In person'],
      experienceLevels: ['Junior associates',  'Partners / executives'],
      certifications: ['iPEC',  'ILCT'],
      yearsOfCoaching: '0-5 yrs',
      languages: ['English'],
      phone: 6562542568,
      degree1: 'BS in Psychology',
      degree2: 'Life Coach',
      degree3: ''
    },
    social: {
      socialLinks: {
        twitter: 'https://www.twitter.com',
        facebook: '',
        google: '',
        linkedIn: 'https://www.linkedin.com',
        instagram: '',
        quora: ''
      },
      connections: {
        twitter: {
          profileImg: require('@src/assets/images/avatars/11-small.png').default,
          id: 'johndoe'
        },
        google: {
          profileImg: require('@src/assets/images/avatars/3-small.png').default,
          id: 'luraweber'
        },
        facebook: {},
        github: {}
      }
    },
    notification: {
      commentOnArticle: true,
      answerOnForm: true,
      followMe: false,
      newAnnouncements: true,
      productUpdates: true,
      blogDigest: false
    }
  }
}

mock.onGet('/account-setting-coach/data').reply(() => [200, data.accountSetting])
