// ** Initial State
const initialState = {
  profileStats: {},
  selectedVideo: {},
  isLoadingVideo: true,
  sessionDashboardList: [],
  totalSessions: 0,
  params: {},
  engagementsList: [],
  isLoadingCoachPage: true,
  data: [],
  trainingData: [],
  total: 1,
  trainingTotal: 0,
  params: {},
  selectedRequest: {},
  isLoadingEngagements: true,
  isLoadingTrainings: true
}

const homeReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'COACH_GET_PROFILE_STATS':
      return { ...state, profileStats: action.profileStats }
    case 'COACH_GET_MEDIADAILY':
      return { ...state, selectedVideo: action?.selectedVideo ?? null, isLoadingVideo: false }
    case 'GET_COACH_HOMEPAGE_SESSIONS':
      return {
        ...state,
        sessionDashboardList: action.sessionList,
        totalSessions: action.totalPages,
        isLoadingCoachPage: false,
        params: action.params
      }
    case 'SET_ENGAGEMENT_REQUESTS_TABLE_LOADING_STATUS':
      return { ...state, isLoadingEngagements: action.data }
    case 'SET_TRAINING_REQUESTS_TABLE_LOADING_STATUS':
      return { ...state, isLoadingTrainings: action.data }
    case 'GET_COACH_ENGAGEMENT_REQUESTS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        selectedRequest: {},
        isLoadingEngagements: false
      }
    case 'GET_COACH_ENGAGEMENT_TRAININGS':
      return {
        ...state,
        trainingData: action.data,
        trainingTotal: action.totalPages,
        params: action.params,
        selectedRequest: {},
        isLoadingTrainings: false
      }
    case 'GET_COACH_SESSION_WIDGET':
      return { ...state, nextSession: action?.nextSession }
    default:
      return { ...state }
  }
}
export default homeReducer
