// ** Initial State
const initialState = {
    data: [],
    total: 1,
    params: {},
    isLoadingCoaches: true
}

const adminCoaches = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ADMIN_COACHES_LIST':
            return {
                ...state,
                data: action.data,
                total: action.totalRecords,
                params: action.params,
                isLoadingCoaches: false
            }
        case 'SET_ADMIN_COACHES_TABLE_LOADING_STATUS':
            return { ...state, isLoadingCoaches: action.data }
        default:
            return { ...state }
    }
}
export default adminCoaches
