// ** Initial State
const initialState = {
  orgDetail: {},
  orgOfficeList: [],
  orgPracticeGroupList: [],
  totalOffices: 1,
  totalPractice: 1,
  params: {}
}

const OrganizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ORG_GET_ORGANIZATION_DATA':
      return { ...state, orgDetail: action.data }
    case 'ORG_GET_OFFICELIST':
      return {
        ...state,
        orgOfficeList: action.data,
        totalOffices: action.totalRecords,
        params: action.params
      }
    case 'ORG_GET_PRACTICEGROUPLIST':
      return {
        ...state,
        orgPracticeGroupList: action.data,
        totalPractice: action.totalRecords,
        params: action.params
      }
    case 'ORG_UPDATE_ORGANIZATION_LOGO_REF':
      state.orgDetail.orgLogo = action.newOrgLogoRefS3
      return { ...state }
    default:
      return { ...state }
  }
}
export default OrganizationReducer
