// ** Initial State
const initialState = {
    data: [],
    total: 1,
    params: {},
    materialDetail: {},
    selectedMaterial: {},
    isLoading: true
}

const materials = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MATERIALS_LOADING_STATUS':
            return { ...state, isLoading: action.data }
        case 'MATERIAL_GET_MEDIAUPLOADS_LIST':
            return {
                ...state,
                data: action.data,
                total: action.totalRecords,
                params: action.params,
                isLoading: false
            }
        case 'GET_MATERIAL_DETAILS':
            return { ...state, selectedMaterial: action?.selectedMaterial ?? {} }
        case 'MATERIAL_CLEAR_SELECTED_MEDIAUPLOADS':
            return { ...state, materialDetail: {} }
        default:
            return { ...state }
    }
}
export default materials
