import mock from '../mock'

const data = {
  accountSetting: {
    general: {
      avatar: require('@src/assets/images/portrait/small/avatar-s-11.jpg').default,
      username: 'johndoe',
      pronouns: 'His',
      firstName: 'John',
      lastName: 'Doe',
      email: 'jdoe@cov.com',
      company: 'IBM Technology'
    },
    info: {
      bio: '',
      dob: null,
      city: 'Los Angeles',
      state: 'California',
      website: '',
      linkedIn: 'https://www.linkedin.com',
      phone: 6562542568
    },
    organization: {
      bio: "Our distinctively collaborative culture allows us to be truly one team globally, drawing on the diverse experience of lawyers and advisors across the firm by seamlessly sharing insight and expertise. What sets us apart is our ability to combine the tremendous strength in our litigation, investigations, and corporate practices with deep knowledge of policy and policymakers, and one of the world's leading regulatory practices. This enables us to create novel solutions to our clients' toughest problems, successfully try their toughest cases and deliver commercially practical advice of the highest quality.",
      logo: require('@src/assets/images/organizationlogos/covingtonlogo.gif').default,
      organizationName: 'Covington & Burling, LLP',
      mailingNumber: '415 Mission Street',
      city: 'San Francisco',
      state: 'California',
      zipcode: '94105',
      coachingContactName: 'Luis Mego',
      coachingContactEmail: 'lmego@cov.com',
      billingContactName: 'Ali Sarafzadeh',
      billingContactEmail: 'asarafzadeh@cov.com',
      aptNumber: '5400',
      totalLawyers: 1200,
      openEngagements: 100
    },
    social: {
      socialLinks: {
        twitter: 'https://www.twitter.com',
        facebook: '',
        google: '',
        linkedIn: 'https://www.linkedin.com',
        instagram: '',
        quora: ''
      },
      connections: {
        twitter: {
          profileImg: require('@src/assets/images/avatars/11-small.png').default,
          id: 'johndoe'
        },
        google: {
          profileImg: require('@src/assets/images/avatars/3-small.png').default,
          id: 'luraweber'
        },
        facebook: {},
        github: {}
      }
    },
    notification: {
      commentOnArticle: true,
      answerOnForm: true,
      followMe: false,
      newAnnouncements: true,
      productUpdates: true,
      blogDigest: false
    }
  }
}

mock.onGet('/account-setting/data').reply(() => [200, data.accountSetting])
