// ** Initial State
const initialState = {
  allData: [],
  data: [],
  trainingData: [],
  total: 1,
  params: {},
  isLoadingEngagements: true,
  isLoadingTrainings: true
}

const adminEngagements = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ADMIN_ENGAGEMENT_TABLE_LOADING_STATUS':
      return { ...state, isLoadingEngagements: action.data }
    case 'SET_ADMIN_TRAINING_TABLE_LOADING_STATUS':
      return { ...state, isLoadingTrainings: action.data }
    case 'GET_ADMIN_ENGAGEMENTS_LIST':
      return {
        ...state,
        data: action.data,
        total: action.totalRecords,
        params: action.params,
        isLoadingEngagements: false
      }
    case 'GET_ADMIN_ENGAGEMENTS_TRAININGS_LIST':
      return {
        ...state,
        trainingData: action.data,
        trainingTotal: action.totalRecords,
        params: action.params,
        isLoadingTrainings: false
      }
    default:
      return { ...state }
  }
}
export default adminEngagements
