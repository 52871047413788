// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  sessionDetails: {},
  isLoadingSession: true
}

const sessions = (state = initialState, action) => {
  switch (action.type) {
    case 'SESSIONS_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        isLoadingSession: false
      }
    case 'SET_SESSIONS_TABLE_LOADING_STATUS':
      return { ...state, isLoadingSession: action.data }
      case 'SESSIONS_GET_SESSION_DATA':
      return { ...state, sessionDetails: action.sessionDetails }
      case 'SESSIONS_CLEAR_SELECTED_SESSION':
      return { ...state, sessionDetails: {} }
    default:
      return { ...state }
  }
}
export default sessions
