import { reorderArray } from '@utils'

// ** Initial State
const initialState = {
  data: [],
  total: 1,
  chapters: [],
  lessons: [],
  params: {},
  courseDetails: {},
  isLoadingChapterForCurriculum: true,
  isLoadingCourse: true,
  isLoadingLesson: true,
  showFormAdd: false,
  showFormEdit: false,
  showFormChapterEdit: false,
  showPreview: false,
  selectedChapter: {},
  selectedLesson: null,
  courseProgress: 0,
  curriculum: {},
  courseName: null,
  isLoading: false,
  lastLessonId: null,
  engagementLink: null,
  engagementName: null,
  chapterDetails: {},
  surveyData: {},
  surveyGroupFailed: [false, false, false],
  surveyResponseData: {},
  surveyReflectionsData: {}
}

const courses = (state = initialState, action) => {
  switch (action.type) {
    case 'COURSES_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        isLoadingCourse: false
      }
    case 'SET_COURSE_TABLE_LOADING_STATUS':
      return { ...state, isLoadingCourse: action.data }
    case 'COURSES_GET_CHAPTERS':
      const chaptersDataOther = Array.from(action.data)
      const lessonsDataFullOther = []
      chaptersDataOther.forEach(u => {
        if (u.lessons && Array.isArray(u.lessons) && u.lessons.length > 0) {
          lessonsDataFullOther.push(...u.lessons)
        }
      })
      const lastLessonIdOther = lessonsDataFullOther.length > 0 ? lessonsDataFullOther[lessonsDataFullOther.length - 1].id : null
      return { ...state, chapters: action.data, isLoadingChapterForCurriculum: false, lastLessonId: lastLessonIdOther }
    case 'COURSES_REORDER_CHAPTERS':
      return { ...state, chapters: reorderArray(state.chapters, action.data.sourceIndex, action.data.destinationIndex) }
    case 'COURSES_REORDER_LESSONS':
      const chapters = Array.from(state.chapters)
      const chapterSelected = chapters.filter(u => u.id === action.data.chapterId)
      const lessonsOrdered = reorderArray(chapterSelected[0].lessons, action.data.sourceIndex, action.data.destinationIndex)
      const chapterIndexOrdered = chapters.findIndex(u => u.id === action.data.chapterId)
      if (chapterIndexOrdered >= 0) {
        chapters[chapterIndexOrdered].lessons = lessonsOrdered
      }
      return { ...state, chapters }
    case 'COURSES_GET_COURSEDATA':
      return { ...state, courseDetails: action.data, isLoadingChapterForCurriculum: false }
    case 'COURSES_GET_LESSONS':
      return { ...state, lessons: action.data }
    case 'COURSES_CLEAR_DATA':
      return { ...state, courseDetails: {} }
    case 'COURSES_SHOW_FORM_ADD':
      return { ...state, showFormAdd: true, showFormEdit: false, showFormChapterEdit: false, isLoading: false }
    case 'COURSES_SHOW_FORM_EDIT':
      return { ...state, showFormEdit: true, showFormAdd: false, showFormChapterEdit: false, isLoading: false }
    case 'COURSES_SHOW_FORM_CHAPTER_EDIT':
      return { ...state, showFormChapterEdit: true, showFormEdit: false, showFormAdd: false, isLoading: false }
    case 'COURSES_HIDE_FORM_EDIT':
      return { ...state, showFormEdit: false, showFormAdd: false, showFormChapterEdit: false, isLoading: true }
    case 'COURSES_HIDE_FORM_EDIT_WITHOUT_LOADING':
      return { ...state, showFormEdit: false, showFormAdd: false, showFormChapterEdit: false }
    case 'COURSES_SHOW_LESSON_PREVIEW':
      return { ...state, showPreview: true, isLoading: false }
    case 'COURSES_HIDE_LESSON_PREVIEW':
      return { ...state, showPreview: false, isLoading: true }
    case 'COURSES_HIDE_LESSON_PREVIEW_WITHOUT_LOADING':
      return { ...state, showPreview: false }
    case 'COURSES_HIDE_LESSON_PREVIEW_RESET_PROGRESS_BAR':
      return { ...state, showPreview: false, courseProgress: 0 }
    case 'LESSON_LOADING_OFF':
      return { ...state, isLoading: false }
    case 'COURSES_HIDE_LESSON_VIEW':
      return { ...state, showPreview: false, isLoading: true }
    case 'COURSES_SET_CHAPTER':
      return { ...state, selectedChapter: action.data, selectedLesson: null }
    case 'COURSES_SET_LESSON':
      return { ...state, selectedLesson: action.data, showFormEdit: true }
    case 'COURSES_GET_LESSON':
      return { ...state, selectedLesson: action.data, showFormEdit: true, isLoadingLesson: false }
    case 'LESSON_CLEAR_CONTENTDATA':
      return {
        ...state,
        selectedLesson: {
          ...state.selectedLesson,
          mediaupload: null
        }
      }
    case 'COURSES_SET_COMPLETED_LESSON':
      const lessonId = action.data.lessonId
      const chapterId = action.data.chapterId
      const chaptersArray = Array.from(state.chapters)
      const chapterIndexSelected = chaptersArray.findIndex(u => u.id === chapterId)
      const chaptersSelected = chaptersArray.find(u => u.id === chapterId)
      const lessonsArray = Array.from(chaptersSelected.lessons)
      const lessonIndexSelected = lessonsArray.findIndex(u => u.id === lessonId)
      const lessonSelected = {
        ...(lessonsArray.find(u => u.id === lessonId)),
        completed: true
      }

      chaptersArray[chapterIndexSelected].lessons[lessonIndexSelected] = lessonSelected

      if (chaptersArray[chapterIndexSelected].lessons.filter(u => u.completed === undefined ||
        u.completed === null ||
        u.completed === false).length === 0) {
        chaptersArray[chapterIndexSelected].completed = true
      }
      const lessonsArrayFull = []
      chaptersArray.forEach(u => {
        if (u.lessons && Array.isArray(u.lessons) && u.lessons.length > 0) {
          lessonsArrayFull.push(...u.lessons)
        }

      })

      const progressCourse = Array.isArray(lessonsArrayFull) ? (Math.round((lessonsArrayFull.filter(u => u.completed === true).length / lessonsArrayFull.length) * 100)) : 0

      return {
        ...state,
        chapters: chaptersArray,
        courseProgress: progressCourse,
        selectedLesson: {
          ...(state.selectedLesson),
          completed: true
        }
      }
    case 'COURSES_GET_CURRICULUM':
      const chaptersData = Array.from(action.data.chapterList)
      const lessonsDataFull = []
      chaptersData.forEach(u => {
        if (u.lessons && Array.isArray(u.lessons) && u.lessons.length > 0) {
          lessonsDataFull.push(...u.lessons)
        }
      })
      const lastLessonId = lessonsDataFull.length > 0 ? lessonsDataFull[lessonsDataFull.length - 1].id : null

      return {
        ...state,
        chapters: action.data.chapterList,
        courseName: action.data.courseName,
        engagementName: action.data.engagementName ?? null,
        engagementLink: action.data.engagementLink ?? null,
        courseProgress: action.data.progressCourse,
        isLoadingChapterForCurriculum: false,
        lastLessonId
      }
    case 'COURSES_GET_CHAPTERDATA':
      return { ...state, chapterDetails: action.data, showFormChapterEdit: true }
    case 'COURSES_GET_SURVEYDATA':
      return { ...state, surveyData: action.data }
    case 'COURSES_CLEAN_SURVEYDATA':
      return { ...state, surveyData: [] }
    case 'COURSES_SURVEY_FAILED':
      return { ...state, surveyGroupFailed: action.data }
    case 'COURSES_GET_SURVEYRESPONSEDATA':
      return { ...state, surveyResponseData: action.data }
    case 'COURSES_GET_SURVEYREFLECTIONSDATA':
      return { ...state, surveyReflectionsData: action.data }
    default:
      return { ...state }
  }
}
export default courses
