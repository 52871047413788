// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: {},
  orgDetails: {},
  countData: null,
  countEngagementData: null,
  isLoadingOrganization: true
}

const users2 = (state = initialState, action) => {
  const test = { ...state, orgDetails: action.data }
  switch (action.type) {
    case 'GET_ORGANIZATION_DATA':
      return {
        ...state,
        orgDetails: action.data
      }
    case 'GET_ORGANIZATION_USERS_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        isLoadingOrganization: false
      }
    case 'USER2_GET_USER':
      return { ...state, selectedUser: action.selectedUser }
    case 'USER2_ADD_USER':
      return { ...state }
    case 'USER2_CLEAR_SELECTED_USER':
      return { ...state, selectedUser: {} }
    case 'DELETE_USER':
      return { ...state }
    case 'GET_COUNT_DATA':
      return { ...state, countData: action.orgTotalUsers }
    case 'GET_COUNT_ENGAGEMENT_DATA':
      return { ...state, countEngagementData: action.orgActiveEngagements }
    case 'VALIDATE_EMAIL':
      return { ...state, response: action.response }
    case 'SET_MY_ORGANIZATION_TABLE_LOADING_STATUS':
      return { ...state, isLoadingOrganization: action.data }
    default:
      return { ...state }
  }
}
export default users2
