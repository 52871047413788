// ** Initial State
const initialState = {
  allData: [],
  data: [],
  totalTimeRequest: 1,
  params: {},
  isLoadingTimeRequest: true
}

const adminTimeRequest = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ADMIN_TIMEREQUEST_TABLE_LOADING_STATUS':
      return { ...state, isLoadingTimeRequest: action.data }
    case 'GET_ADMIN_TIMEREQUEST_LIST':
      return {
        ...state,
        data: action.data,
        totalTimeRequest: action.totalRecords,
        params: action.params,
        isLoadingTimeRequest: false
      }
    default:
      return { ...state }
  }
}
export default adminTimeRequest
