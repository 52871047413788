// ** Initial State
const initialState = {
    data: [],
    total: 1,
    params: {},
    selectedTraining: {},
    isLoadingTrainings: true
}

const adminTrainings = (state = initialState, action) => {
    switch (action.type) {
        case 'ADMIN_GET_TRAININGS':
            return {
                ...state,
                data: action.data.data.trainingList,
                total: action.data.data.recordsMetadata.totalRecords,
                params: action.params,
                isLoadingTrainings: false
            }
        case 'ADMIN_GET_TRAINING_DATA':
            return {
                ...state,
                selectedTraining: action.selectedTraining
            }
        case 'SET_ADMIN_TRAININGS_TABLE_LOADING_STATUS':
            return { ...state, isLoadingTrainings: action.data }
        default:
            return { ...state }
    }
}
export default adminTrainings
