// ** Logo
import logo from '@src/assets/images/logo/logo.png'

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner vh-100'>
      {/* <img className='fallback-logo' src={logo} alt='logo' /> */}
      {/* <img className='fallback-logo' src={''} alt='logo' /> */}
      <div className='loading'>
        <div className='effect-1 effects' style={{ borderLeft: '7px solid #007aff' }}></div>
        <div className='effect-2 effects' style={{ borderLeft: '8px solid #007aff' }}></div>
        <div className='effect-3 effects' style={{ borderLeft: '5px solid #007aff' }}></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
