// ** Initial State
const initialState = {
    isLoading: true,
    selectedVideoCall: {}
}

const videoCalls = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_VIDEOCALL_LOADING_STATUS':
            return { ...state, isLoading: action.data }
        case 'GET_VIDEOCALL':
            return { ...state, selectedVideoCall: action?.data?.sessionVideoCall ?? {} }
        default:
            return { ...state }
    }
}
export default videoCalls
