// ** Initial State
const initialState = {
    data: [],
    total: 1,
    params: {},
    myOrganizationDetails: {},
    isLoadingOrganizations: true
}

const adminOrganizations = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ADMIN_ORGANIZATION_LIST':
            return {
                ...state,
                data: action.data,
                total: action.totalRecords,
                params: action.params,
                isLoadingOrganizations: false
            }
        case 'GET_ADMIN_ORGANIZATION_MY_ORG_DETAILS':
            return {
                ...state,
                myOrganizationDetails: action.data
            }
        case 'SET_ADMIN_ORG_TABLE_LOADING_STATUS':
            return { ...state, isLoadingOrganizations: action.data }
        default:
            return { ...state }
    }
}
export default adminOrganizations
