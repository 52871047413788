// ** Initial State
const initialState = {
    data: [],
    total: 1,
    selectedDocument: {},
    params: {},
    isLoading: true
}

const documentuploads = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DOCUMENT_UPLOADS_LOADING_STATUS':
            return { ...state, isLoading: action.data }
        case 'GET_DOCUMENTUPLOADS_LIST':
            return {
                ...state,
                data: action.data,
                total: action.totalRecords,
                params: action.params,
                isLoading: false
            }
        case 'DOCUMENTPLOAD_UPDATE_THUMBNAIL_REF':
            state.selectedDocument.thumbnail = action.newThumbnailRefS3
            return { ...state }
        case 'GET_DOCUMENTUPLOAD':
            return { ...state, selectedDocument: action?.selectedDocument ?? {} }
        case 'DOCUMENTUPLOAD_CLEAR_SELECTED_DOCUMENTUPLOADS':
            return { ...state, selectedDocument: {} }
        default:
            return { ...state }
    }
}
export default documentuploads
