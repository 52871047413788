import dayjs from 'dayjs'
// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#2991e51a', // for option hover bg-color
    primary: '#2991e5', // for selected option bg-color
    neutral10: '#2991e5', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const reorderArray = (arrayList, sourceIndex, destinationIndex) => {
  const result = Array.from(arrayList)
  const [removed] = result.splice(sourceIndex, 1)
  result.splice(destinationIndex, 0, removed)
  return result
}

// ** Returns if a event start in 10 minutes
export const calcDiffMinutes = (startDateUTC) => {
  const dateFuture = new Date(startDateUTC)
  const dateNow = new Date()
  const seconds = Math.floor((dateFuture - (dateNow)) / 1000)

  if (seconds === 0) {
    return true
  } else if ((seconds > 0 && seconds <= 300) || (seconds < 0 && seconds > -1800)) {
    return true
  } else {
    return false
  }
}

export const formatDateTimestamp = (dateTimestamp, dayjsFormatString, keepUTC = false) => {
  if (keepUTC) {
    const dayjsDate = dayjs.utc(dateTimestamp).format(dayjsFormatString)
    return dayjsDate
  }
  const dayjsDate = dayjs(dateTimestamp)
  return dayjsDate.format(dayjsFormatString)
}

export const generateTimeForCalendar = (startDate, startTime, offset) => {

  const startDateString = startDate.split('T')[0]
  const [time, period] = startTime.split(' ')
  const [hours, minutes] = time.split(':')
  const adjustedHours = period === 'PM' ? (parseInt(hours) === 12 ? 12 : parseInt(hours) + 12) : (parseInt(hours) === 12 ? '00' : hours)
  const startTimeString = `${adjustedHours}:${minutes}`


  const timeZone = offset >= 0 ? `+${offset < 10 ? '0' : ''}${offset}:00` : `-${Math.abs(offset) < 10 ? '0' : ''}${offset * -1}:00`
  const newDate = dayjs(`${startDateString}T${startTimeString}:00.000Z`).add(30, 'minute')
  const endDate = `${formatDateTimestamp(newDate, 'YYYY-MM-DDTHH:mm:ss', true)}${timeZone}`
  startDate = `${startDateString}T${startTimeString}:00${timeZone}`

  return [startDate, endDate]
}