// ** Initial State
const initialState = {
  userDetail: {},
  selectedUser: {},
  params: {},
  sessions: [],
  sessionsParams: [],
  totalSessions: 1
}

const users = (state = initialState, action) => {
  const test = { ...state, orgDetails: action.data }
  switch (action.type) {
    case 'USER_GET_PROFILE_DATA':
      return { ...state, userDetail: action.userDetails }
    case 'LAWFIRM_GET_USER':
      return { ...state, selectedUser: action.selectedUser }
    case 'USER_CLEAR_SELECTED_USER':
      return { ...state, userDetail: {}, selectedUser: {} }
    case 'USER_UPDATE_PROFILE_PICTURE_REF':
      state.userDetail.avatar = action.newProfilePictureRefS3
      return { ...state }
    case 'REQUEST_GET_SESSION_ENGAGEMENTS':
      return {
        ...state,
        totalSessions: action.totalPages,
        sessionsParams: action.params,
        sessions: action.data.sessionsEngagements
      }
    default:
      return { ...state }
  }
}
export default users
