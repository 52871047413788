const initialState = {
  advisors: [],
  advisorDetail: {},
  params: {},
  totalAdvisors: 0,
  isLoading: true
}

const ecommerceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FIND_A_ADVISOR_LOADING_STATUS':
      return { ...state, isLoading: action.data }
    case 'GET_ADVISORS':
      return {
        ...state,
        advisors: action.data.data.coachesList,
        params: action.params,
        totalAdvisors: action.data.data.recordsMetadata.totalRecords,
        advisorDetail: {},
        isLoading: false
      }
    case 'GET_ADVISOR':
      return { ...state, advisorDetail: action?.data?.coachDetails ?? {} }
    case 'ADVISOR_CLEAR_SELECTED_ADVISOR_PROFILE':
      return { ...state, advisorDetail: {} }
    default:
      return state
  }
}

export default ecommerceReducer
