// ** Initial State
const initialState = {
  orgOfficeOptions: [],
  orgPracticeGroupOptions: [],
  coacheeOptions: [],
  userRoleOptions: [],
  coachOptions: [],
  organizationOptions: [],
  engagementOptions: [],
  surveyTemplateOptions: [],
  engagementCoachOptions: [],
  coachesOptions: []
}

const dropdownsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_OFFICEOPTIONS':
      return { ...state, orgOfficeOptions: action.orgOfficeOptions }
    case 'GET_PRACTICEGROUPOPTIONS':
      return { ...state, orgPracticeGroupOptions: action.orgPracticeGroupOptions }
    case 'GET_COACHEESOPTIONS':
      return { ...state, coacheeOptions: action.coacheeOptions }
    case 'GET_ROLESOPTIONS':
      return { ...state, userRoleOptions: action.userRoleOptions }
    case 'GET_COACHOPTIONS':
      return { ...state, coachOptions: action.coachOptions }
    case 'GET_ORGANIZATIONOPTIONS':
      return { ...state, organizationOptions: action.organizationOptions }
    case 'GET_MEDIAUPLOADSOPTIONS':
      return { ...state, mediauploadsOptions: action.mediauploadsOptions }
    case 'GET_TAGSOPTIONS':
      return { ...state, tagsOptions: action.tagsOptions }
    case 'GET_ENGAGEMENTOPTIONS':
      return { ...state, engagementOptions: action.engagementOptions }
    case 'GET_SURVEYTEMPLATENOPTIONS':
      return { ...state, surveyTemplateOptions: action.surveyTemplateOptions }
    case 'GET_ENGAGEMENTCOACHEEOPTIONS':
      return { ...state, engagementCoachOptions: action.engagementCoachOptions }
    case 'GET_ENGAGEMENTCOACHESOPTIONS':
      return { ...state, coachesOptions: action.coachesOptions }
    case 'GET_COACH_ORGANIZATIONOPTIONS':
      return { ...state, organizationOptionsForCoach: action.organizationOptionsForCoach }
    default:
      return state
  }
}

export default dropdownsReducer
