// ** Initial State
const initialState = {
    data: [],
    total: 1,
    selectedVideo: {},
    params: {},
    isLoading: true
}

const mediaUploads = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MEDIA_UPLOADS_LOADING_STATUS':
            return { ...state, isLoading: action.data }
        case 'GET_MEDIAUPLOADS_LIST':
            return {
                ...state,
                data: action.data,
                total: action.totalRecords,
                params: action.params,
                isLoading: false
            }
        case 'MEDIAUPLOAD_UPDATE_THUMBNAIL_REF':
            state.selectedVideo.thumbnail = action.newThumbnailRefS3
            return { ...state }
        case 'GET_MEDIAUPLOAD':
            return { ...state, selectedVideo: action?.selectedVideo ?? {} }
        case 'MEDIAUPLOAD_CLEAR_SELECTED_MEDIAUPLOADS':
            return { ...state, selectedVideo: {} }
        default:
            return { ...state }
    }
}
export default mediaUploads
