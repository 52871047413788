import mock from '../mock'
/*eslint-disable */

const data = {
  chapters: [{
    id: 1,
    name: "Chapter #1",
    draft: false,
    courseId: "628fb2b1fc13ae1fae00001e",
    orgId: "628fb2b1fc13ae1fae00001f",
    position: 1,
    createdOn: "5/25/2022",
    lessons: [{
      id: 1,
      name: "Lesson #1",
      draft: false,
      description: 'Description lesson 1',
      contentType: 'document',
      defaultIcon: 'file',
      chapterId: 1,
      courseId: "628fb2b1fc13ae1fae00001e",
      orgId: "628fb2b1fc13ae1fae00001f",
      position: 1,
      createdOn: "5/25/2022"
    }, {
      id: 2,
      name: "Lesson #2",
      draft: false,
      description: 'Description lesson 2',
      contentType: 'text',
      defaultIcon: 'file-text',
      chapterId: 1,
      courseId: "628fb2b1fc13ae1fae00001e",
      orgId: "628fb2b1fc13ae1fae000021",
      position: 2,
      createdOn: "6/14/2021"
    }, {
      id: 3,
      name: "Lesson #3",
      draft: false,
      description: 'Description lesson 3',
      contentType: 'video',
      defaultIcon: 'video',
      chapterId: 1,
      courseId: "628fb2b1fc13ae1fae00001e",
      orgId: "628fb2b1fc13ae1fae000023",
      position: 3,
      createdOn: "11/4/2021"
    }]
  }, {
    id: 2,
    name: "Chapter #2",
    draft: true,
    courseId: "628fb2b1fc13ae1fae00001e",
    orgId: "628fb2b1fc13ae1fae000021",
    position: 2,
    createdOn: "6/14/2021",
    lessons: [{
      id: 4,
      name: "Lesson #4",
      draft: true,
      description: 'Description lesson 4',
      contentType: 'text',
      defaultIcon: 'file-text',
      chapterId: 2,
      courseId: "628fb2b1fc13ae1fae00001e",
      orgId: "628fb2b1fc13ae1fae000025",
      position: 4,
      createdOn: "6/21/2021"
    }, {
      id: 5,
      name: "Lesson #5",
      draft: true,
      description: 'Description lesson 5',
      contentType: 'text',
      defaultIcon: 'file-text',
      chapterId: 3,
      courseId: "628fb2b1fc13ae1fae00001e",
      orgId: "628fb2b1fc13ae1fae000027",
      position: 5,
      createdOn: "6/29/2021"
    }]
  }, {
    id: 3,
    name: "Chapter #3",
    draft: true,
    courseId: "628fb2b1fc13ae1fae00001e",
    orgId: "628fb2b1fc13ae1fae000023",
    position: 3,
    createdOn: "11/4/2021"
  }, {
    id: 4,
    name: "Chapter #4",
    draft: true,
    courseId: "628fb2b1fc13ae1fae00001e",
    orgId: "628fb2b1fc13ae1fae000025",
    position: 4,
    createdOn: "6/21/2021"
  }, {
    id: 5,
    name: "Chapter #5",
    draft: true,
    courseId: "628fb2b1fc13ae1fae00001e",
    orgId: "628fb2b1fc13ae1fae000027",
    position: 5,
    createdOn: "6/29/2021"
  }],
  lessons: [{
    id: 1,
    name: "Lesson #1",
    draft: false,
    description: 'Description lesson 1',
    contentType: 'document',
    defaultIcon: 'file',
    chapterId: 1,
    courseId: "628fb2b1fc13ae1fae00001e",
    orgId: "628fb2b1fc13ae1fae00001f",
    position: 1,
    createdOn: "5/25/2022"
  }, {
    id: 2,
    name: "Lesson #2",
    draft: false,
    description: 'Description lesson 2',
    contentType: 'text',
    defaultIcon: 'file-text',
    chapterId: 1,
    courseId: "628fb2b1fc13ae1fae00001e",
    orgId: "628fb2b1fc13ae1fae000021",
    position: 2,
    createdOn: "6/14/2021"
  }, {
    id: 3,
    name: "Lesson #3",
    draft: false,
    description: 'Description lesson 3',
    contentType: 'video',
    defaultIcon: 'video',
    chapterId: 1,
    courseId: "628fb2b1fc13ae1fae00001e",
    orgId: "628fb2b1fc13ae1fae000023",
    position: 3,
    createdOn: "11/4/2021"
  }, {
    id: 4,
    name: "Lesson #4",
    draft: true,
    description: 'Description lesson 4',
    contentType: 'text',
    defaultIcon: 'file-text',
    chapterId: 2,
    courseId: "628fb2b1fc13ae1fae00001e",
    orgId: "628fb2b1fc13ae1fae000025",
    position: 4,
    createdOn: "6/21/2021"
  }, {
    id: 5,
    name: "Lesson #5",
    draft: true,
    description: 'Description lesson 5',
    contentType: 'text',
    defaultIcon: 'file-text',
    chapterId: 3,
    courseId: "628fb2b1fc13ae1fae00001e",
    orgId: "628fb2b1fc13ae1fae000027",
    position: 5,
    createdOn: "6/29/2021"
  }],
  contacts: [
    {
      id: 1,
      fullName: 'Felecia Rower',
      role: 'Frontend Developer',
      about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',

      avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
      status: 'offline'
    },
    {
      id: 2,
      fullName: 'Adalberto Granzin',
      role: 'UI/UX Designer',
      about:
        'Toffee caramels jelly-o tart gummi bears cake I love ice cream lollipop. Sweet liquorice croissant candy danish dessert icing. Cake macaroon gingerbread toffee sweet.',
      avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
      status: 'busy'
    },
    {
      id: 3,
      fullName: 'Joaquina Weisenborn',
      role: 'Town planner',
      about:
        'Soufflé soufflé caramels sweet roll. Jelly lollipop sesame snaps bear claw jelly beans sugar plum sugar plum.',
      avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
      status: 'busy'
    },
    {
      id: 4,
      fullName: 'Verla Morgano',
      role: 'Data scientist',
      about:
        'Chupa chups candy canes chocolate bar marshmallow liquorice muffin. Lemon drops oat cake tart liquorice tart cookie. Jelly-o cookie tootsie roll halvah.',
      avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
      status: 'online'
    },
    {
      id: 5,
      fullName: 'Margot Henschke',
      role: 'Dietitian',
      about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
      avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
      status: 'busy'
    },
    {
      id: 6,
      fullName: 'Sal Piggee',
      role: 'Marketing executive',
      about:
        'Toffee caramels jelly-o tart gummi bears cake I love ice cream lollipop. Sweet liquorice croissant candy danish dessert icing. Cake macaroon gingerbread toffee sweet.',
      avatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default,
      status: 'online'
    },
    {
      id: 7,
      fullName: 'Miguel Guelff',
      role: 'Special educational needs teacher',
      about:
        'Biscuit powder oat cake donut brownie ice cream I love soufflé. I love tootsie roll I love powder tootsie roll.',
      avatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
      status: 'online'
    },
    {
      id: 8,
      fullName: 'Mauro Elenbaas',
      role: 'Advertising copywriter',
      about:
        'Bear claw ice cream lollipop gingerbread carrot cake. Brownie gummi bears chocolate muffin croissant jelly I love marzipan wafer.',
      avatar: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
      status: 'away'
    },
    {
      id: 9,
      fullName: 'Bridgett Omohundro',
      role: 'Designer, television/film set',
      about:
        'Gummies gummi bears I love candy icing apple pie I love marzipan bear claw. I love tart biscuit I love candy canes pudding chupa chups liquorice croissant.',
      avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
      status: 'offline'
    },
    {
      id: 10,
      fullName: 'Zenia Jacobs',
      role: 'Building surveyor',
      about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
      avatar: require('@src/assets/images/portrait/small/avatar-s-10.jpg').default,
      status: 'away'
    }
  ]
}
/*eslint-enable */
// ------------------------------------------------
// GET: Return CHAPTERS
// ------------------------------------------------
mock.onGet('/apps/courses/chapters').reply(() => [200, data.chapters])
// ------------------------------------------------
// GET: Return CHAPTERS
// ------------------------------------------------
mock.onGet('/apps/courses/lessons').reply(() => [200, data.lessons])
// ------------------------------------------------
// GET: Return User Profile
// ------------------------------------------------
mock.onGet('/apps/chat/users/profile-user').reply(() => [200, data.profileUser])

// ------------------------------------------------
// GET: Return Single CHAPTER
// ------------------------------------------------
mock.onGet('/apps/chat/get-chapter').reply(config => {
  // Get event id from URL

  let chapterId = config.id

    //  Convert Id to number
    chapterId = Number(chapterId)

  const chapter = data.chapters.find(c => c.id === chapterId)
  return [200, { chapter }]
})