// ** Initial State
const initialState = {
  coachDetail: {},
  trainings: [],
  trainingDetail: {},
  params: {},
  totalTrainings: 0,
  isLoadingTraining: true
}

const coachProfileReducer = (state = initialState, action) => {
  const test = { ...state, orgDetails: action.data }
  switch (action.type) {
    case 'COACH_GET_PROFILE_DATA':
      return { ...state, coachDetail: action.coachDetails }
    case 'COACH_CLEAR_SELECTED_COACH':
      return { ...state, coachDetail: {} }
    case 'COACH_GET_TRAININGS':
      return {
        ...state,
        trainings: action.data.data.trainingList,
        params: action.params,
        totalTrainings: action.data.data.recordsMetadata.totalRecords,
        trainingDetail: {},
        isLoadingTraining: false
      }
    case 'COACH_UPDATE_PROFILE_PICTURE_REF':
      state.coachDetail.image = action.newProfilePictureRefS3
      return { ...state }
    case 'SET_COACH_TRAINING_TABLE_LOADING_STATUS':
      return { ...state, isLoadingTraining: action.data }
    default:
      return { ...state }
  }
}
export default coachProfileReducer
