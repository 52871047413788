// ** Initial State
const initialState = {
    data: [],
    total: 1,
    params: {},
    isLoadingSessionEngagements: true
}

const coachSessionEngagements = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_COACH_ENGAGEMENT_LOADING_STATUS':
            return { ...state, isLoadingSessionEngagements: action.data }
        case 'GET_COACH_ENGAGEMENT_SESSION_LIST':
            return {
                ...state,
                data: action.data,
                total: action.totalRecords,
                params: action.params,
                isLoadingSessionEngagements: false
            }
        default:
            return { ...state }
    }
}
export default coachSessionEngagements
