const initialState = {
  products: [],
  wishlist: [],
  cart: [],
  coachDetail: {},
  params: {},
  totalProducts: 0,
  isLoading: true
}

const ecommerceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FIND_A_COACH_LOADING_STATUS':
      return { ...state, isLoading: action.data }
    case 'GET_COACHES':
      return {
        ...state,
        products: action.data.data.coachesList,
        params: action.params,
        totalProducts: action.data.data.recordsMetadata.totalRecords,
        coachDetail: {},
        isLoading: false
      }
    case 'GET_WISHLIST':
      return { ...state, wishlist: action.data.products }
    case 'DELETE_WISHLIST_ITEM':
      return { ...state }
    case 'GET_CART':
      return { ...state, cart: action.data.products }
    case 'DELETE_CART_ITEM':
      return { ...state }
    case 'ADD_TO_WISHLIST':
      return { ...state }
    case 'ADD_TO_CART':
      return { ...state }
    case 'GET_PRODUCT':
      return { ...state, coachDetail: action?.data?.coachDetails ?? {} }
    case 'COACH_CLEAR_SELECTED_COACH_PROFILE':
      return { ...state, coachDetail: {} }
    default:
      return state
  }
}

export default ecommerceReducer
