import mock from '../mock'
/* eslint-disable */
import { paginateArray, sortCompare, randomDate, getRandomInt } from '../utils'

const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
const nextWeek = new Date(nextDay.getTime() + 7 * 24 * 60 * 60 * 1000)

const data = {
  products: [
    {
      id: 1,
      name: 'Bryce Hoffman',
      slug: 'bruce-hoffman',
      description:
        'I am the author of the bestselling business classic American Icon: Alan Mulally and the Fight to Save Ford Motor Company. Since my book was first published in 2012, I have helped CEOs and other senior executives at corporations around the world understand and implement the game-changing ideas I learned from my mentor Alan Mulally,',
      coachTagLine: 'Career Onboarding, Transition, Outplacement Expert',
      image: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
      rating: 2,
      yearsCoaching: '6 yrs',
      coachingSpecialties: [
        'Business Development',
        'Coaching and Mentoring',
        'Communication',
        'Conflict resolution',
        'Diversity, Equity and Inclusion',
        'Emotional Intelligence',
        'Executive Presence',
        'Imposter Syndrome',
        'Leadership',
        'LinkedIn',
        'Making Partner',
        'Management',
        'Mindfulness',
        'Mindset',
        'Practice Management',
        'Presentation Skills',
        'Resilience',
        'Self-Confidence',
        'Time Management',
        'Wellness and Well-being',
        'Women’s Empowerment'
      ],
      website: 'www.sample.com',
      linkedin: 'linkedin.com/in',
      languages: [
        'English',
      ],
      location: 'Miami, FL',
      instructionType: [
        '1 to 1',
      ],
      modalities: [
        'In person',
      ],
      experienceLevel: [
        'Junior associates',
        'Partners / executives',
      ],
      certifications: [
        'iPEC',
        'ILCT',
      ],
      degree1: {
        title: 'BS in Psychology',
        school: 'Boston University'
      },
      degree2: {
        title: 'Life Coach',
        school: 'University of Florida'
      },
      degree3: {
        title: 'BA Business Administration',
        school: 'University of Cumbria'
      }
    },
    {
      id: 2,
      name: 'Jenny Shiers',
      slug: 'jenny-shiers',
      description:
        'I am a tenured HR leader with a passion for personal development and leadership coaching. I fully subscribe to the power of coaching as a great way to grow as a person and in your chosen field, often providing the tools to unlock potential or guide a coachee to tackle challenges themselves. I joined Salesforce in 2011, so am also well versed in the opportunities and challenges of working in this unique, inspiring, fast paced and challenging environment.',
      coachTagLine: 'Corporate / Organizational, Executive Leadership Development Expert',
      image: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
      yearsCoaching: '11 yrs',
      coachingSpecialties: [
        'Conflict resolution',
        'Diversity, Equity and Inclusion',
        'LinkedIn',
        'Making Partner',
        'Management',
        'Mindfulness',
        'Women’s Empowerment '
      ],
      website: 'www.sample.com',
      linkedin: 'linkedin.com/in',
      languages: [
        'English',
        'Spanish',
      ],
      location: 'Los Angeles, CA',
      instructionType: [
        '1 to 1',
      ],
      modalities: [
        'In person',
      ],
      experienceLevel: [
        'Junior associates',
        'Partners / executives',
      ],
      certifications: [
        'iPEC',
        'ILCT',
      ],
      degree1: {
        title: 'BS in Psychology',
        school: 'Boston University'
      },
      degree2: {
        title: 'Life Coach',
        school: 'University of Florida'
      },
      degree3: {
        title: 'BA Business Administration',
        school: 'University of Cumbria'
      }
    },
    {
      id: 3,
      name: 'Alfreda McCray',
      slug: 'alfreda-mccray',
      description:
        'My aspirations as a coach are to help leaders deepen their leadership presence, connect to their values and energy, and remain creative so they can keep doing awesome things in the world. As an Executive/Leadership Coach, I believe that leadership is relational. I partner to help clients enhance relational capability with self and others. As such, my areas of focus include social-emotional skills, well-being, self-care, and mindfulness.',
      coachTagLine: 'Executive Leadership Development, Interpersonal Relationships, Personal Growth',
      image: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
      yearsCoaching: '28 yrs',
      website: 'www.sample.com',
      linkedin: 'linkedin.com/in',
      languages: [
        'English',
        'french',
      ],
      location: 'Bakersfield, CA',
      instructionType: [
        '1 to 1',
      ],
      modalities: [
        'In person',
      ],
      experienceLevel: [
        'Junior associates',
        'Partners / executives',
      ],
      certifications: [
        'iPEC',
        'ILCT',
      ],
      degree1: {
        title: 'BS in Psychology',
        school: 'Boston University'
      },
      degree2: {
        title: 'Life Coach',
        school: 'University of Florida'
      },
      degree3: {
        title: 'BA Business Administration',
        school: 'University of Cumbria'
      }
    },
    {
      id: 4,
      name: 'Neiman Hatcher',
      slug: 'neiman-hatcher',
      description:
        'Great leaders are born, but they can also be made. Effective leadership development helps organizations engage their people, unlock potential, and experience unprecedented growth. Yet, many organizations suffer from lack of confidence in their current and future leadership strategy. As an Industrial/Organizational Psychologist I have deep insights into what great leadership looks like and have partnered with some of the top organizations of today in the development of high performing leaders and teams. My leadership coaching programs are precisely targeted by industry, strategy and leadership level to help my clients develop the next generation of leaders – smart, dynamic professionals prepared to lead their organizations into the future with confidence.',
      coachTagLine: 'Health and Wellness, Personal Growth Guru',
      image: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
      yearsCoaching: '2 yrs',
      coachingSpecialties: [
        'Business Development',
        'Coaching and Mentoring',
        'Imposter Syndrome',
        'Mindset',
        'Women’s Empowerment'
      ],
      languages: [
        'English',
        'Mandarin',
      ],
      website: 'www.sample.com',
      linkedin: 'linkedin.com/in',
      location: 'San Diego, CA',
      instructionType: [
        '1 to 1',
      ],
      modalities: [
        'In person',
      ],
      experienceLevel: [
        'Junior associates',
        'Partners / executives',
      ],
      certifications: [
        'iPEC',
        'ILCT',
      ],
      degree1: {
        title: 'BS in Psychology',
        school: 'Boston University'
      },
      degree2: {
        title: 'Life Coach',
        school: 'University of Florida'
      },
      degree3: {
        title: 'BA Business Administration',
        school: 'University of Cumbria'
      }
    },
    {
      id: 5,
      name: 'Daniel Lucido',
      slug: 'daniel-lucido',
      description: 'Daniel Lucido is a Leadership Trainer, Talent Optimizer and Executive Coach with 20 years of experience leading small to mid-size organizations. He believes that the people matter, just as much profit and excellence in inclusive leadership practices has a statistically profound impact on both. Daniel specializes in helping his client’s sort through the chaos & clutter to find the best route forward and to find purpose & significance so they can unleash their individual leadership potential and drive bottom line business results. ',
      coachTagLine: 'Executive Leadership Development, High potentials',
      image: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
      yearsCoaching: '21 yrs',
      coachingSpecialties: [
        'Business Development',
        'Management',
        'Mindfulness',
        'Mindset',
        'Practice Management',
      ],
      website: 'www.sample.com',
      linkedin: 'linkedin.com/in',
      languages: [
        'English',
        'Deutsch',
      ],
      location: 'San Fransisco, CA',
      instructionType: [
        '1 to 1',
      ],
      modalities: [
        'In person',
      ],
      experienceLevel: [
        'Junior associates',
        'Partners / executives',
      ],
      certifications: [
        'iPEC',
        'ILCT',
      ],
      degree1: {
        title: 'BS in Psychology',
        school: 'Boston University'
      },
      degree2: {
        title: 'Life Coach',
        school: 'University of Florida'
      },
      degree3: {
        title: 'BA Business Administration',
        school: 'University of Cumbria'
      }
    },
    {
      id: 6,
      name: 'Laura Chavis',
      slug: 'laura-chavis',
      description:
        'Laura has spent the last two decades building successful real estate ventures. Her bestselling books, Buy It, Rent It, Profit! and The Landlord Entrepreneur, are considered essential guides for investors looking to build lasting wealth through multi-family real estate and property management. While at the helm of her private equity and asset management firm Chavis Capital, Laura also launched The Multifamily Matrix, and Laura Chavis Coaching & Consulting a global leader in education and training for Multifamily investors, landlords, asset managers & property managers. Under Laura’s leadership, these companies have developed one of the industry’s most recognized property management certifications, which has trained more than 50,000 landlords to date. An active investor and property manager, Laura has also captivated audiences across the country as an in-demand speaker.',
      coachTagLine: 'Executive Leadership Development, Groups and Teams, Interpersonal Relationships',
      image: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default,
      yearsCoaching: '15 yrs',
      coachingSpecialties: [
        'Business Development',
        'Coaching and Mentoring',
      ],
      website: 'www.sample.com',
      linkedin: 'linkedin.com/in',
      languages: [
        'English',
        'Spanish',
      ],
      location: 'Miami, FL',
      instructionType: [
        'Large Group (11 +)',
      ],
      modalities: [
        'In person',
      ],
      experienceLevel: [
        'Junior associates',
        'Partners / executives',
      ],
      certifications: [
        'iPEC',
        'ILCT',
      ],
      degree1: {
        title: 'BS in Psychology',
        school: 'Boston University'
      },
      degree2: {
        title: 'Life Coach',
        school: 'University of Florida'
      },
      degree3: {
        title: 'BA Business Administration',
        school: 'University of Cumbria'
      }
    },
    {
      id: 7,
      name: 'Jakoba Franzen',
      slug: 'jakoba-franzen',
      description:
        'In 2019 that was all taken away. I lost my wife, and my daughters lost their mommy, to an unnamed disease. That event turned our world inside out. I took a new executive job that I shouldn’t have. I tried to balance being a parent, a provider, and a grieving widower in the midst of difficult trauma. After a year of feeling stuck, victimized, and lost I realized I needed to make a change and begin to reinvent my life. I left my job, started a business, and began the difficult journey of shoring up my relationship with my children. Single parenting isnt easy! Now, Im putting everything Ive learned about surviving trauma, finding resilience, and 30 years of leadership experience into a coaching business designed to help you.',
      coachTagLine: 'Small Business, Startup Expert',
      image: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
      yearsCoaching: '11 yrs',
      coachingSpecialties: [
        'Business Development',
        'Coaching and Mentoring',
        'Communication',
        'Wellness and Well-being',
        'Women’s Empowerment'
      ],
      website: 'www.sample.com',
      linkedin: 'linkedin.com/in',
      languages: [
        'English',
      ],
      location: 'Los Angeles, CA',
      instructionType: [
        '1 to 1',
        'Large Group (11 +)',
      ],
      modalities: [
        'In person',
      ],
      experienceLevel: [
        'Junior associates',
        'Partners / executives',
      ],
      certifications: [
        'iPEC',
        'ILCT',
      ],
      degree1: {
        title: 'BS in Psychology',
        school: 'Boston University'
      },
      degree2: {
        title: 'Life Coach',
        school: 'University of Florida'
      },
      degree3: {
        title: 'BA Business Administration',
        school: 'University of Cumbria'
      }
    },
    {
      id: 8,
      name: 'Andy Johnson',
      slug: 'andy-johnson',
      description:
        'Andy’s coaching practice began during his time in the London Fire Brigade in the early 2000s.  The provided Andy with early experience in coaching at a senior level and across culturally diverse teams.  He has successfully parlayed that experience to coach executives, senior leaders, managers, athletes and team members in organisations both in the UK and Australia across industries including financial services, resources, infrastructure, marketing, legal, non-for-profit, professional sports, local government and health sectors.  Coaching engagements are primarily developmental, behavioural, performance and career focussed, working with counterparts thought transformation, whilst maintaining deliverables.',
      coachTagLine: 'Executive Leadership Development Guru',
      image: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
      yearsCoaching: '10 yrs',
      coachingSpecialties: [
        'Self-Confidence',
        'Time Management',
        'Wellness and Well-being',
        'Women’s Empowerment'
      ],
      website: 'www.sample.com',
      linkedin: 'linkedin.com/in',
      languages: [
        'English',
      ],
      location: 'San Jose, CA',
      instructionType: [
        '1 to 1',
        'Small Group (2 - 10)',
      ],
      modalities: [
        'Virtual',
      ],
      experienceLevel: [
        'Junior associates',
        'Partners / executives',
      ],
      certifications: [
        'iPEC',
        'ILCT',
      ],
      degree1: {
        title: 'BS in Psychology',
        school: 'Boston University'
      },
      degree2: {
        title: 'Life Coach',
        school: 'University of Florida'
      },
      degree3: {
        title: 'BA Business Administration',
        school: 'University of Cumbria'
      }
    },
    {
      id: 9,
      name: 'Scott Brown',
      slug: 'scott-brown',
      description:
        'I coach leaders and leadership teams, and I teach Entrepreneurial Leadership at Kenan-Flagler Business School. When coaching leaders, our focus is on facilitating measurable change to the behaviors that most impact their ability to lead. When coaching leadership teams, our focus is on ensuring that the team operates at its highest capacity as a unit. I am certified with the International Coach Federation (ACC), which binds me to a code of ethics and ensures that I sharpen my skills regularly. I am also affiliated with the Marshall Goldsmith Corporation (Stakeholder Centered Coaching), so you can rest easy knowing that I use a proven process for achieving measurable change. I am also a 5 Behaviors partner through Wiley, which gives me access to tools underpinned by Patrick Lencionis extensive work on building strong teams.',
      coachTagLine: 'Career Onboarding, Transition, Outplacement',
      image: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
      yearsCoaching: '14 yrs',
      coachingSpecialties: [
        'Executive Presence',
        'Imposter Syndrome',
        'Leadership',
        'LinkedIn',
        'Making Partner',
        'Management',
      ],
      website: 'www.sample.com',
      linkedin: 'linkedin.com/in',
      languages: [
        'English',
      ],
      location: 'San Diego, CA',
      instructionType: [
        '1 to 1',
      ],
      modalities: [
        'In person',
      ],
      experienceLevel: [
        'Junior associates',
        'Partners / executives',
      ],
      certifications: [
        'iPEC',
        'ILCT',
      ],
      degree1: {
        title: 'BS in Psychology',
        school: 'Boston University'
      },
      degree2: {
        title: 'Life Coach',
        school: 'University of Florida'
      },
      degree3: {
        title: 'BA Business Administration',
        school: 'University of Cumbria'
      }
    },
    {
      id: 10,
      name: 'Danielle Kasch',
      slug: 'danielle-kasch',
      description:
        'I’m here to help you identify and make the changes in your life and leadership that make it more purposeful and rewarding. Since starting as coaching three years ago, I’ve worked with leaders from tech, healthcare, and education on a range of topics including fearless leadership, strategic thinking, effective feedback, difficult conversations, emotional intelligence, empathetic communication, and burnout.',
      coachTagLine: 'Executive Leadership Development, Groups and Teams, Personal Growth, High potentials',
      image: require('@src/assets/images/portrait/small/avatar-s-10.jpg').default,
      yearsCoaching: '1 yr',
      coachingSpecialties: [
        'LinkedIn',
        'Making Partner',
        'Management',
        'Mindfulness'
      ],
      website: 'www.sample.com',
      linkedin: 'linkedin.com/in',
      languages: [
        'English',
        'Spanish',
      ],
      location: 'Orlando, FL',
      instructionType: [
        'Small Group (2 - 10)',
      ],
      modalities: [
        'In person',
      ],
      experienceLevel: [
        'Junior associates',
        'Partners / executives',
      ],
      certifications: [
        'iPEC',
        'ILCT',
      ],
      degree1: {
        title: 'BS in Psychology',
        school: 'Boston University'
      },
      degree2: {
        title: 'Life Coach',
        school: 'University of Florida'
      },
      degree3: {
        title: 'BA Business Administration',
        school: 'University of Cumbria'
      }
    },
    {
      id: 11,
      name: 'Ruth Wilkinson',
      slug: 'ruth-wilkinson',
      description:
        'My name is Ruth Wilkinson; I am a member of the European Mentor and Coaching Council (EMCC) and a registered member of a British Association for Counselling and Psychotherapy (BACP). I have a Masters degree in Coaching and Mentoring, and in recent years I have expanded my practice to life, relationship and personal coaching. I worked with my clients holistically to support and guide the "whole" person, not just elements and aspects.',
      coachTagLine: 'Career Transition, Executive Leadership Development',
      image: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
      yearsCoaching: '2 yrs',
      coachingSpecialties: [
        'Executive Presence',
        'Imposter Syndrome',
        'Leadership',
      ],
      website: 'www.sample.com',
      linkedin: 'linkedin.com/in',
      languages: [
        'English',
      ],
      location: 'Los Angeles, CA',
      instructionType: [
        '1 to 1',
        'Small Group (2 - 10)',
      ],
      modalities: [
        'In person',
        'Virtual',
      ],
      experienceLevel: [
        'Junior associates',
        'Partners / executives',
      ],
      certifications: [
        'iPEC',
        'ILCT',
      ],
      degree1: {
        title: 'BS in Psychology',
        school: 'Boston University'
      },
      degree2: {
        title: 'Life Coach',
        school: 'University of Florida'
      },
      degree3: {
        title: 'BA Business Administration',
        school: 'University of Cumbria'
      }
    }
  ],
  userWishlist: [
    { id: 1, productId: 1 },
    { id: 2, productId: 2 }
  ],
  userCart: [
    { id: 1, productId: 1, qty: 1 },
    { id: 2, productId: 2, qty: 1 },
    { id: 3, productId: 3, qty: 1 },
    { id: 4, productId: 4, qty: 1 },
    { id: 5, productId: 5, qty: 1 }
  ]
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return coaches
// ------------------------------------------------

mock.onGet('/coaches/findcoaches').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    sortBy = 'featured',
    perPage = 10,
    page = 1,
    selectedCoachingSpecialties = [],
    selectedYearsOfCoachingExperience = [],
    selectedCoachingExperienceLevels = [],
    selectedInstructionTypes = [],
    selectedCoachingModalities = [],
    selectedCoachingLanguages = []
  } = config.params

  const queryLowered = q.toLowerCase()

  const filteredData = data.products.filter(product => product.name.toLowerCase().includes(queryLowered))

  let sortDesc = false
  const sortByKey = (() => {
    if (sortBy === 'price-desc') {
      sortDesc = true
      return 'price'
    }
    if (sortBy === 'price-asc') {
      return 'price'
    }
    sortDesc = true
    return 'id'
  })()

  const sortedData = filteredData.sort(sortCompare(sortByKey))
  if (sortDesc) sortedData.reverse()

  const paginatedData = JSON.parse(JSON.stringify(paginateArray(sortedData, perPage, page)))

  paginatedData.forEach(product => {
    /* eslint-disable no-param-reassign */
    product.degree1 = null
    product.degree2 = null
    product.degree3 = null
    product.certifications = null
    product.experienceLevel = null
    product.modalities = null
    product.instructionType = null
    product.languages = null
    product.website = null
    product.linkedin = null
    product.yearsCoaching = null
    product.slug = null
    /* eslint-enable */
  })

  return [
    200,
    {
      success: true,
      data: {
        recordsMetadata: {
          totalRecords: filteredData.length
        },
        coachesList: paginatedData
      }
    }
  ]
})

// ------------------------------------------------
// GET: Return Single Product
// ------------------------------------------------
mock.onGet(/\/apps\/findcoaches\/coaches\/\d+/).reply(config => {
  // Get product id from URL
  let productId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  productId = Number(productId)

  const productIndex = data.products.findIndex(p => p.id === productId)
  const product = data.products[productIndex]

  if (product) {
    // Add data of wishlist and cart
    product.isInWishlist = data.userWishlist.findIndex(p => p.productId === product.id) > -1
    product.isInCart = data.userCart.findIndex(p => p.productId === product.id) > -1

    // * Add Dummy data for details page
    product.colorOptions = ['primary', 'success', 'warning', 'danger', 'info']

    return [200, { success: true, data: { coachDetails: { ...product } } }]
  }
  return [404]
})

mock.onGet('/coaches').reply(config => {

  const coachId = Number(config.params.id)

  const coachIndex = data.products.findIndex(p => p.id === coachId)
  const coach = data.products[coachIndex]

  if (coach) return [200, { success: true, data: { coachDetails: { ...coach } } }]

  return [404]
})

// // ------------------------------------------------
// // GET: Return Wishlist Products
// // ------------------------------------------------
// mock.onGet('/apps/ecommerce/wishlist').reply(() => {
//   const products = data.userWishlist.map(wishlistProduct => {
//     const product = data.products.find(p => p.id === wishlistProduct.productId)
//     product.isInCart = data.userCart.findIndex(p => p.productId === wishlistProduct.productId) > -1
//     return product
//   })

//   return [200, { products }]
// })

// // ------------------------------------------------
// // GET: Return Cart Products
// // ------------------------------------------------
// mock.onGet('/apps/ecommerce/cart').reply(() => {
//   const products = data.userCart.map(cartProduct => {
//     const product = data.products.find(p => p.id === cartProduct.productId)

//     // Other data
//     product.isInWishlist = data.userWishlist.findIndex(p => p.productId === cartProduct.productId) > -1
//     product.qty = cartProduct.qty
//     product.shippingDate = randomDate(nextDay, nextWeek)
//     product.offers = getRandomInt(1, 4)
//     product.discountPercentage = getRandomInt(3, 20)

//     return product
//   })

//   return [200, { products }]
// })

// // ------------------------------------------------
// // POST: Add Item in user Cart
// // ------------------------------------------------
// mock.onPost('/apps/ecommerce/cart').reply(config => {
//   // Get product from post data
//   const { productId } = JSON.parse(config.data)

//   const { length } = data.userCart
//   let lastId = 0
//   if (length) lastId = data.userCart[length - 1].i

//   data.userCart.push({
//     id: lastId + 1,
//     productId,
//     qty: 1
//   })

//   return [201]
// })

// // ------------------------------------------------
// // DELETE: Remove Item from user Cart
// // ------------------------------------------------
// mock.onDelete(/\/apps\/ecommerce\/cart\/\d+/).reply(config => {
//   // Get product id from URL
//   let productId = config.url.substring(config.url.lastIndexOf('/') + 1)

//   // Convert Id to number
//   productId = Number(productId)

//   const productIndex = data.userCart.findIndex(i => i.productId === productId)
//   if (productIndex > -1) data.userCart.splice(productIndex, 1)

//   return [200]
// })

// // ------------------------------------------------
// // POST: Add Item in user Wishlist
// // ------------------------------------------------
// mock.onPost('/apps/ecommerce/wishlist').reply(config => {
//   // Get product from post data
//   const { productId } = JSON.parse(config.data)

//   const { length } = data.userWishlist
//   let lastId = 0
//   if (length) lastId = data.userWishlist[length - 1].i

//   data.userWishlist.push({
//     id: lastId + 1,
//     productId: Number(productId)
//   })

//   return [201]
// })

// // ------------------------------------------------
// // DELETE: Remove Item from user Wishlist
// // ------------------------------------------------
// mock.onDelete(/\/apps\/ecommerce\/wishlist\/\d+/).reply(config => {
//   // Get product id from URL
//   let productId = config.url.substring(config.url.lastIndexOf('/') + 1)

//   // Convert Id to number
//   productId = Number(productId)

//   const productIndex = data.userWishlist.findIndex(i => i.productId === productId)
//   if (productIndex > -1) data.userWishlist.splice(productIndex, 1)

//   return [200]
// })
