// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  isLoadingCourse: true
}

const adminCourses = (state = initialState, action) => {
  switch (action.type) {
    case 'ADMIN_COURSE_PROGRESS_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        isLoadingCourse: false
      }
    case 'SET_ADMIN_COURSE_PROGRESS_TABLE_LOADING_STATUS':
      return { ...state, isLoadingCourse: action.data }
    default:
      return { ...state }
  }
}
export default adminCourses
