import mock from '../mock'

const data = {
  // blog list
  videoList: [
    {
      thumbnail: require('@src/assets/images/slider/02.jpg').default,
      name: 'The Best Features Coming to iOS and Web design',
      id: 1,
      status: 'completed',
      uploadedOn: 'Jan 10, 2020',
      tags: ['Quote', 'Fashion'],
      size: '14.6 MB',
      length: '00:20:00'
    },
    {
      thumbnail: require('@src/assets/images/slider/06.jpg').default,
      name: 'Latest Quirky Opening Sentence or Paragraph',
      id: 2,
      status: 'pending',
      uploadedOn: 'Jan 10, 2020',
      tags: ['Gaming', 'Video'],
      size: '70.5 MB',
      length: '00:12:00'
    },
    {
      thumbnail: require('@src/assets/images/slider/04.jpg').default,
      name: 'Share an Amazing and Shocking Fact or Statistic',
      id: 3,
      status: 'inactive',
      uploadedOn: 'Jan 10, 2020',
      tags: ['Gaming', 'Food'],
      size: '25.0 MB',
      length: '00:03:00'
    },
    {
      thumbnail: require('@src/assets/images/slider/03.jpg').default,
      name: 'Withhold a Compelling Piece of Information',
      id: 4,
      status: 'active',
      uploadedOn: 'Jan 10, 2020',
      tags: ['Video'],
      size: '10.1 MB',
      length: '00:25:00'
    },
    {
      thumbnail: require('@src/assets/images/slider/09.jpg').default,
      name: 'Unadvertised Bonus Opening: Share a Quote',
      id: 5,
      status: 'inactive',
      uploadedOn: 'Jan 10, 2020',
      tags: ['Quote', 'Fashion'],
      size: '76.2 MB',
      length: '00:15:00'
    },
    {
      thumbnail: require('@src/assets/images/slider/10.jpg').default,
      name: 'Ships at a distance have Every Man’s Wish on Board',
      id: 6,
      status: 'pending',
      uploadedOn: 'Jan 10, 2020',
      tags: ['Fashion', 'Video'],
      size: '76.0 MB',
      length: '00:32:00'
    }
  ],

  // material
  materialList: [
    {
      thumbnail: require('@src/assets/images/slider/02.jpg').default,
      name: 'The Best Features Coming to iOS and Web design',
      id: 1,
      createdBy: {
        avatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
        userFullName: 'Ghani Pradita'
      },
      createdOn: 'Jan 10, 2020',
      tags: ['Quote', 'Fashion'],
      size: '14.6 MB',
      length: '00:20:00',
      mediatype: 'PDF'
    },
    {
      thumbnail: require('@src/assets/images/slider/06.jpg').default,
      name: 'Latest Quirky Opening Sentence or Paragraph',
      id: 2,
      createdBy: {
        avatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
        userFullName: 'Ghani Pradita'
      },
      createdOn: 'Jan 10, 2020',
      tags: ['Gaming', 'Video'],
      size: '70.5 MB',
      length: '00:12:00',
      mediatype: 'Video'
    },
    {
      thumbnail: require('@src/assets/images/slider/04.jpg').default,
      name: 'Share an Amazing and Shocking Fact or Statistic',
      id: 3,
      createdBy: {
        avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
        userFullName: 'Chad Alexander'
      },
      createdOn: 'Jan 10, 2020',
      tags: ['Gaming', 'Food'],
      size: '25.0 MB',
      length: '00:03:00',
      mediatype: 'PPT'
    },
    {
      thumbnail: require('@src/assets/images/slider/03.jpg').default,
      name: 'Withhold a Compelling Piece of Information',
      id: 4,
      createdBy: {
        avatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
        userFullName: 'Ghani Pradita'
      },
      createdOn: 'Jan 10, 2020',
      tags: ['Video'],
      size: '10.1 MB',
      length: '00:25:00',
      mediatype: 'Video'
    },
    {
      thumbnail: require('@src/assets/images/slider/09.jpg').default,
      name: 'Unadvertised Bonus Opening: Share a Quote',
      id: 5,
      createdBy: {
        avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
        userFullName: 'Chad Alexander'
      },
      createdOn: 'Jan 10, 2020',
      tags: ['Quote', 'Fashion'],
      size: '76.2 MB',
      length: '00:15:00',
      mediatype: 'PDF'
    },
    {
      thumbnail: require('@src/assets/images/slider/10.jpg').default,
      name: 'Ships at a distance have Every Man’s Wish on Board',
      id: 6,
      createdBy: {
        avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
        userFullName: 'Chad Alexander'
      },
      createdOn: 'Jan 10, 2020',
      tags: ['Fashion', 'Video'],
      size: '76.0 MB',
      length: '00:32:00',
      mediatype: 'Video'
    }
  ],

  // detail
  videoDetail: {
    id: 6,
    thumbnail: require('@src/assets/images/banner/banner-12.jpg').default,
    name: 'The Best Features Coming to iOS and Web design',
    description:
      'Before you get into the nitty-gritty of coming up with a perfect title, start with a rough draft: your working title. What is that, exactly? A lot of people confuse working titles with topics. Let\'s clear that Topics are very general and could yield several different blog posts. Think "raising healthy kids," or "kitchen storage." A writer might look at either of those topics and choose to take them in very, very different directions.A working title, on the other hand, is very specific and guides the creation of a single blog post. For example, from the topic "raising healthy kids," you could derive the following working title See how different and specific each of those is? That\'s what makes them working titles, instead of overarching topics.',
    status: 'pending',
    createdBy: {
      avatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
      userFullName: 'Ghani Pradita'
    },
    createdOn: 'Jan 10, 2020',
    tags: ['Gaming', 'Video', 'Fashion'],
    size: '76.0 MB',
    length: '00:32:00',
    url: 'https://d3nuo098yv0fuc.cloudfront.net/out/v1/9805558d186d461d817434a7e2748ba2/c6942b759b1942f2a685b1352ed06e07/66743714cc5e4d1db44859357d794a50/index.m3u8',
    public: false,
    sharedWith: [
      {
        entityType: "Registered Nurses",
        entityName: "Todd Ruggles",
        organization: "Statoil ASA",
        sharedOn: "5/23/2021"
      }, {
        entityType: "Air Traffic Control",
        entityName: "Lou Eyton",
        organization: "Tenet Healthcare Corporation",
        sharedOn: "8/13/2021"
      }, {
        entityType: "Urban Studies",
        entityName: "Sharl Watkiss",
        organization: "Ever-Glory International Group, Inc.",
        sharedOn: "8/3/2021"
      }, {
        entityType: "Zooarchaeology",
        entityName: "Koenraad Mallabund",
        organization: "Farmer Brothers Company",
        sharedOn: "4/19/2021"
      }, {
        entityType: "JP54",
        entityName: "Desmond Roy",
        organization: "Richmont Mines, Inc.",
        sharedOn: "7/13/2021"
      }
    ]
  },

  // edit
  videoEdit: {
    id: 6,
    thumbnail: require('@src/assets/images/banner/banner-12.jpg').default,
    name: 'The Best Features Coming to iOS and Web design',
    description:
      'Before you get into the nitty-gritty of coming up with a perfect title, start with a rough draft: your working title. What is that, exactly? A lot of people confuse working titles with topics. Let\'s clear that Topics are very general and could yield several different blog posts. Think "raising healthy kids," or "kitchen storage." A writer might look at either of those topics and choose to take them in very, very different directions.A working title, on the other hand, is very specific and guides the creation of a single blog post. For example, from the topic "raising healthy kids," you could derive the following working title See how different and specific each of those is? That\'s what makes them working titles, instead of overarching topics.',
    status: 'pending',
    createdBy: {
      avatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
      userFullName: 'Ghani Pradita'
    },
    createdOn: 'Jan 10, 2020',
    tags: ['Gaming', 'Video', 'Fashion'],
    size: '76.0 MB',
    length: '00:32:00',
    url: 'https://d3nuo098yv0fuc.cloudfront.net/out/v1/9805558d186d461d817434a7e2748ba2/c6942b759b1942f2a685b1352ed06e07/66743714cc5e4d1db44859357d794a50/index.m3u8',
    public: { value: 'false', label: 'False' }
  }
}

mock.onGet('/learningcenter/list/video').reply(() => [200, data.videoList])
mock.onGet('/learningcenter/list/material').reply(() => [200, data.materialList])
mock.onGet('/learningcenter/list/video/detail').reply(() => [200, data.videoDetail])
mock.onGet('/learningcenter/list/video/edit').reply(() => [200, data.videoDetail])
