// ** Initial State
const initialState = {
    page: 1,
    videoList: [],
    videoListData: [],
    refetch: false,
    loading: false,
    loadingFetch: true,
    keywords: null,
    selectedTags: null
}

const videoGrid = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_LOADING_FETCH':
            return { ...state, loadingFetch: true }
        case 'GET_VIDEOLIST':
            return { ...state, videoListData: action.videoListData, loadingFetch: false }
        case 'SET_VIDEOLIST':
            return { ...state, refetch: false, videoList: action.videoList }
        case 'SET_VIDEOLIST_CONCAT':
            return { ...state, refetch: false, videoList: [...state.videoList, ...action.videoList] }
        case 'CLEAN_VIDEOLIST':
            return { ...state, videoList: [], videoListData: [] }
        case 'SET_LOADING':
            return { ...state, loading: action.data }
        case 'SET_PAGE':
            return { ...state, page: action.data }
        case 'SET_KEYWORDS':
            return { ...state, keywords: action.data, page: 1 }
        case 'SET_SELECTED_TAGS':
            return { ...state, selectedTags: action.data, page: 1 }
        case 'SET_INITIAL_STATE': 
            return initialState
        default:
            return { ...state }
    }
}
export default videoGrid