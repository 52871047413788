import moment from 'moment'
import DefaultDropdownOptions from '../dropdowns/dropdownDefaultData'
import mock from '../mock'
import { paginateArray, sortCompare } from '../utils'

const data = {
  users: [
    {
      id: 1,
      firstName: 'Galen',
      lastName: 'Slixby',
      fullName: 'Galen Slixby',
      orgName: 'Covington & Burling, LLP',
      orgId: 10000000,
      experienceLevel: { value: 'CEL0002', label: 'Mid-Level Associate' },
      practiceGroup: { value: '200001', label: 'Corporate Law' },
      office: { value: '100004', label: 'San Francisco Office' },
      engagementStatus: { value: 'pending', label: 'Pending' },
      location: { value: 'L00002', label: 'California' },
      email: 'gslixby0@cov.com',
      avatar: '',
      role: { value: "R00001", label: "Administrator", appliesTo: "lawfirmonly" },
      pronouns: { value: 'DPR0001', label: 'He / Him / His' }
    },
    {
      id: 2,
      firstName: 'Halsey',
      lastName: 'Redmore',
      fullName: 'Halsey Redmore',
      orgName: 'Covington & Burling, LLP',
      orgId: 10000000,
      experienceLevel: { value: 'CEL0004', label: 'Partner / Executive' },
      practiceGroup: { value: '200001', label: 'Corporate Law' },
      office: { value: '100002', label: 'Los Angeles Office' },
      engagementStatus: { value: 'active', label: 'Active' },
      location: { value: 'L00002', label: 'California' },
      email: 'hredmore1@cov.com',
      avatar: require('@src/assets/images/avatars/10.png').default,
      role: { value: 'R00002', label: 'Coachee' },
      pronouns: { value: 'DPR0001', label: 'He / Him / His' }
    },
    {
      id: 3,
      firstName: 'Marjory',
      lastName: 'Sicely',
      fullName: 'Marjory Sicely',
      orgName: 'Covington & Burling, LLP',
      orgId: 10000000,
      experienceLevel: { value: 'CEL0004', label: 'Partner / Executive' },
      practiceGroup: { value: '200002', label: 'Litigation and Investigations' },
      office: { value: '100002', label: 'Los Angeles Office' },
      engagementStatus: { value: 'active', label: 'Active' },
      location: { value: 'L00002', label: 'California' },
      email: 'msicely2@cov.com',
      avatar: require('@src/assets/images/avatars/1.png').default,
      role: { value: 'R00002', label: 'Coachee' },
      pronouns: { value: 'DPR0002', label: 'She / Her / Hers' }
    },
    {
      id: 4,
      firstName: 'Cyrill',
      lastName: 'Risby',
      fullName: 'Cyrill Risby',
      orgName: 'Covington & Burling, LLP',
      orgId: 10000000,
      experienceLevel: { value: 'CEL0005', label: 'Lateral' },
      practiceGroup: { value: '200001', label: 'Corporate Law' },
      office: { value: '100003', label: 'New York Office' },
      engagementStatus: { value: 'notengaged', label: 'Not Engaged' },
      location: { value: 'L00001', label: 'New York' },
      email: 'crisby3@cov.com',
      avatar: require('@src/assets/images/avatars/9.png').default,
      role: { value: 'R00002', label: 'Coachee' },
      pronouns: { value: 'DPR0002', label: 'She / Her / Hers' }
    },
    {
      id: 5,
      firstName: 'Max',
      lastName: 'Hurran',
      fullName: 'Max Hurran',
      orgName: 'Covington & Burling, LLP',
      orgId: 10000000,
      experienceLevel: { value: 'CEL0002', label: 'Mid-Level Associate' },
      practiceGroup: { value: '200002', label: 'Litigation and Investigations' },
      office: { value: '100002', label: 'Los Angeles Office' },
      engagementStatus: { value: 'pending', label: 'Pending' },
      location: { value: 'L00002', label: 'California' },
      email: 'mhurran4@cov.com',
      avatar: require('@src/assets/images/avatars/10.png').default,
      role: { value: 'R00002', label: 'Coachee' },
      pronouns: { value: 'DPR0001', label: 'He / Him / His' }
    },
    {
      id: 6,
      firstName: 'Silvain',
      lastName: 'Halstead',
      fullName: 'Silvain Halstead',
      orgName: 'Covington & Burling, LLP',
      orgId: 10000000,
      experienceLevel: { value: 'CEL0001', label: 'Junior Associate' },
      practiceGroup: { value: '200004', label: 'Industries and Issues' },
      office: { value: '100003', label: 'New York Office' },
      engagementStatus: { value: 'active', label: 'Active' },
      location: { value: 'L00001', label: 'New York' },
      email: 'shalstead5@cov.com',
      avatar: '',
      role: { value: 'R00002', label: 'Coachee' },
      pronouns: { value: 'DPR0002', label: 'She / Her / Hers' }
    },
    {
      id: 7,
      firstName: 'Breena',
      lastName: 'Gallemore',
      fullName: 'Breena Gallemore',
      orgName: 'Covington & Burling, LLP',
      orgId: 10000000,
      experienceLevel: { value: 'CEL0005', label: 'Lateral' },
      practiceGroup: { value: '200002', label: 'Litigation and Investigations' },
      office: { value: '100003', label: 'New York Office' },
      engagementStatus: { value: 'active', label: 'Active' },
      location: { value: 'L00001', label: 'New York' },
      email: 'bgallemore6@cov.com',
      avatar: '',
      role: { value: 'R00002', label: 'Coachee' },
      pronouns: { value: 'DPR0002', label: 'She / Her / Hers' }
    },
    {
      id: 8,
      firstName: 'Kathryne',
      lastName: 'Liger',
      fullName: 'Kathryne Liger',
      orgName: 'Covington & Burling, LLP',
      orgId: 10000000,
      experienceLevel: { value: 'CEL0006', label: 'Professional Staff' },
      practiceGroup: { value: '200004', label: 'Industries and Issues' },
      office: { value: '100002', label: 'Los Angeles Office' },
      engagementStatus: { value: 'pending', label: 'Pending' },
      location: { value: 'L00002', label: 'California' },
      email: 'kliger7@vinaora.com',
      avatar: require('@src/assets/images/avatars/9.png').default,
      role: { value: 'R00002', label: 'Coachee' },
      pronouns: { value: 'DPR0002', label: 'She / Her / Hers' }
    },
    {
      id: 9,
      firstName: "Felix",
      lastName: "Scotfurth",
      fullName: "Felix Scotfurth",
      orgName: 'Covington & Burling, LLP',
      orgId: 10000000,
      experienceLevel: { value: 'CEL0006', label: 'Professional Staff' },
      practiceGroup: { value: '200001', label: 'Corporate Law' },
      office: { value: '100002', label: 'Los Angeles Office' },
      engagementStatus: { value: 'pending', label: 'Pending' },
      location: { value: 'L00002', label: 'California' },
      email: 'fscotfurth8@cov.com',
      avatar: require('@src/assets/images/avatars/2.png').default,
      role: { value: 'R00002', label: 'Coachee' },
      pronouns: { value: 'DPR0001', label: 'He / Him / His' }
    },
    {
      id: 10,
      firstName: 'Jillene',
      lastName: 'Bellany',
      fullName: 'Jillene Bellany',
      orgName: 'Covington & Burling, LLP',
      orgId: 10000000,
      experienceLevel: { value: 'CEL0002', label: 'Mid-Level Associate' },
      practiceGroup: { value: '200003', label: 'Regulatory and Public Policy' },
      office: { value: '100001', label: 'Austin Office' },
      engagementStatus: { value: 'notengaged', label: 'Not Engaged' },
      location: { value: 'L00004', label: 'Texas' },
      email: 'jbellany9@cov.com',
      avatar: require('@src/assets/images/avatars/9.png').default,
      role: { value: 'R00002', label: 'Coachee' },
      pronouns: { value: 'DPR0002', label: 'She / Her / Hers' }
    },
    {
      id: 11,
      firstName: 'Jonah',
      lastName: 'Wharlton',
      fullName: 'Jonah Wharlton',
      orgName: 'Covington & Burling, LLP',
      orgId: 10000000,
      experienceLevel: { value: 'CEL0003', label: 'Senior Associate' },
      practiceGroup: { value: '200003', label: 'Regulatory and Public Policy' },
      office: { value: '100004', label: 'San Francisco Office' },
      engagementStatus: { value: 'completed', label: 'Completed' },
      location: { value: 'L00002', label: 'California' },
      email: 'jwharltona@cov.com',
      avatar: require('@src/assets/images/avatars/4.png').default,
      role: { value: 'R00002', label: 'Coachee' },
      pronouns: { value: 'DPR0001', label: 'He / Him / His' }
    }
  ],
  organizations: [
    {
      orgId: 10000000,
      orgName: 'Covington & Burling, LLP',
      orgLocation: 'San Francisco, CA, USA',
      orgLogo: require('@src/assets/images/organizationlogos/covingtonlogo.gif').default,
      orgTotalUsers: 1240,
      orgActiveEngagements: 33,
      coachingContactName: 'Luis Mego',
      coachingContactEmail: 'lmego@cov.com',
      billingContactName: 'Ali Sarafzadeh',
      billingContactEmail: 'asarafzadeh@cov.com',
      role: { value: 'R00002', label: 'Coachee' },
      customDropdowns: {
        orgOfficeOptions: [
          { value: '100001', label: 'Austin Office', number: 1 },
          { value: '100002', label: 'Los Angeles Office', number: 2 },
          { value: '100003', label: 'New York Office', number: 3 },
          { value: '100004', label: 'San Francisco Office', number: 4 }
        ],
        orgPracticeGroupOptions: [
          { value: '200001', label: 'Corporate Law', number: 0 },
          { value: '200002', label: 'Litigation and Investigations', number: 1 },
          { value: '200003', label: 'Regulatory and Public Policy', number: 2 },
          { value: '200004', label: 'Industries and Issues', number: 3 }
        ]
      }
    }
  ],
  userEngagements: [
    {
      id: 10001,
      orgId: 10000000,
      title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      description: 'Fusce sagittis neque eget ligula posuere tempus. Ut at nibh leo. Donec lacinia lorem nec metus faucibus, sed accumsan elit molestie. Sed fermentum gravida tortor. Praesent sollicitudin urna ac ante faucibus condimentum. Vivamus magna sapien, feugiat eu arcu eget, ornare fringilla libero. Aliquam viverra dui erat, mollis consectetur arcu malesuada pharetra. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      requestedOn: '07-20-21',
      tentativeStartDate: '2021-08-12',
      orgOffice: { orgOfficeId: '100001', orgOfficeName: 'Austin Office' },
      orgPracticeGroup: { orgPracticeGroupId: '200001', orgPracticeGroupName: 'Corporate Law' },
      requestedBy: {
        userId: 1,
        userFullName: 'Galen Slixby',
        userAvatar: ''
      },
      coachees: [
        {
          userId: 1,
          userFullName: 'Galen Slixby',
          userAvatar: ''
        },
        {
          userId: 2,
          userFullName: 'Halsey Redmore',
          userAvatar: require('@src/assets/images/avatars/10.png').default
        }
      ],
      coaches: [
        {
          coachId: 7,
          coachName: 'Jakoba Franzen',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default
        }
      ],
      selectedCoach: {
        coachId: 7,
        coachName: 'Jakoba Franzen',
        coachAvatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default
      },
      currentStatus: { statusId: 'completed', statusName: 'Completed' },
      statusHistory: [
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' }
      ],
      changeHistory: [
        {
          activityId: 'ae9121c6-5090-4da0-aa41-979ca833f374',
          activityTitle: 'Engagement completed',
          activityMeta: '12 min ago',
          activityContent: undefined,
          activityColorClass: 'primary',
          activityCreatedBy: {
            userId: 1,
            userFullName: 'Galen Slixby',
            userAvatar: '',
            userTitle: 'Law Firm Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: undefined
        },
        {
          activityId: '17c9928d-6803-48bf-b06d-27d01a399604',
          activityTitle: 'Engagement Aproved',
          activityMeta: '45 min ago',
          activityContent: 'Engagement approved and transitioned to Active',
          activityColorClass: 'success',
          activityCreatedBy: {
            userId: 1,
            userFullName: 'Galen Slixby',
            userAvatar: '',
            userTitle: 'Law Firm Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: null
        },
        {
          activityId: '03d1cbd5-18a1-4760-9ed3-33dca45e2477',
          activityTitle: 'Quote Uploaded',
          activityMeta: '45 min ago',
          activityContent: undefined,
          activityColorClass: 'warning',
          activityCreatedBy: {
            userId: 1231231,
            userFullName: 'Drew Amoroso',
            userAvatar: '',
            userTitle: 'DueCourse Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: {
            filename: 'Quote #01234.pdf',
            fileUrl: 'https://invoice.com'
          }
        },
        {
          activityId: '20bea752-b0c7-49cc-8dc5-77b5fcf9c5a0',
          activityTitle: 'Engagement Request Modified',
          activityMeta: '2 day ago',
          activityContent: 'Add details to Engagement',
          activityColorClass: 'warning',
          activityCreatedBy: {
            userId: 2,
            userFullName: 'Halsey Redmore',
            userAvatar: require('@src/assets/images/avatars/10.png').default,
            userTitle: null
          },
          activityCreatedOn: '07/21/2021',
          attachment: null
        },
        {
          activityId: '7b81c7e5-a6de-43f0-9321-3e8eab85a854',
          activityTitle: 'Engagement Request Created',
          activityMeta: '3 day ago',
          activityContent: 'Created initial Engagement Request',
          activityColorClass: 'danger',
          activityCreatedBy: {
            userId: 2,
            userFullName: 'Halsey Redmore',
            userAvatar: require('@src/assets/images/avatars/10.png').default,
            userTitle: null
          },
          activityCreatedOn: '07/20/2021',
          attachment: null
        }
      ],
      experienceLevels: [
        { id: 'CEL0001', experienceLevelName: 'Junior Associate' },
        { id: 'CEL0002', experienceLevelName: 'Mid-Level Associate' },
        { id: 'CEL0004', experienceLevelName: 'Partner / Executive' },
        { id: 'CEL0005', experienceLevelName: 'Lateral' }
      ],
      modalities: [
        { id: 'CM00001', modalityName: 'In person' },
        { id: 'CM00002', modalityName: 'Virtual' },
        { id: 'CM00003', modalityName: 'Willing to Travel' }
      ],
      instructionTypes: [
        { id: 'CI00001', instructionTypeName: '1 to 1' },
        { id: 'CI00002', instructionTypeName: 'Small Group (2 - 10)' },
        { id: 'CI00003', instructionTypeName: 'Large Group (11 +)' },
        { id: 'CI00004', instructionTypeName: 'Workshops / Presentations' },
        { id: 'CI00005', instructionTypeName: 'Offsites / Retreats' }
      ],
      languages: [
        { id: 'English', languageName: 'English' },
        { id: 'French', languageName: 'French' },
        { id: 'Spanish', languageName: 'Spanish' }
      ],
      coachingSpecialties: [
        { coachingSpecialtyId: 'CS00001', coachingSpecialtyName: 'Business Development' },
        { coachingSpecialtyId: 'CS00002', coachingSpecialtyName: 'Coaching and Mentoring' },
        { coachingSpecialtyId: 'CS00003', coachingSpecialtyName: 'Communication' }
      ]
    },
    {
      id: 10002,
      orgId: 10000000,
      title: 'Suspendisse dapibus justo nec pretium hendrerit',
      description: 'Quisque ut rutrum massa, non aliquet neque. Nunc sem metus, tempus et orci at, pellentesque porta felis. Phasellus a odio non nulla dapibus rutrum. Duis nec ligula lacus. Phasellus ut ex non dolor suscipit consequat. Donec et lectus dui. Aliquam consectetur dictum laoreet. Pellentesque tincidunt mi vitae lacus scelerisque eleifend. Praesent vel pharetra velit. Ut eget nisi a dolor viverra mollis non nec dui.',
      requestedOn: '07-20-21',
      tentativeStartDate: '2021-08-12',
      orgOffice: { orgOfficeId: '100001', orgOfficeName: 'Austin Office' },
      orgPracticeGroup: { orgPracticeGroupId: '200001', orgPracticeGroupName: 'Corporate Law' },
      requestedBy: {
        userId: 10,
        userFullName: 'Jillene Bellany',
        userAvatar: require('@src/assets/images/avatars/9.png').default
      },
      coachees: [
        {
          userId: 9,
          userFullName: "Felix Scotfurth",
          userAvatar: require('@src/assets/images/avatars/2.png').default
        },
        {
          userId: 10,
          userFullName: 'Jillene Bellany',
          userAvatar: require('@src/assets/images/avatars/9.png').default
        },
        {
          userId: 11,
          userFullName: 'Jonah Wharlton',
          userAvatar: require('@src/assets/images/avatars/4.png').default
        }
      ],
      coaches: [
        {
          coachId: 7,
          coachName: 'Jakoba Franzen',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default
        }
      ],
      selectedCoach: {
        coachId: 7,
        coachName: 'Jakoba Franzen',
        coachAvatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default
      },
      currentStatus: { statusId: 'completed', statusName: 'Completed' },
      statusHistory: [
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' }
      ],
      changeHistory: [
        {
          activityId: 'ae9121c6-5090-4da0-aa41-979ca833f374',
          activityTitle: 'Engagement completed',
          activityMeta: '12 min ago',
          activityContent: undefined,
          activityColorClass: 'primary',
          activityCreatedBy: {
            userId: 1,
            userFullName: 'Galen Slixby',
            userAvatar: '',
            userTitle: 'Law Firm Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: undefined
        },
        {
          activityId: '17c9928d-6803-48bf-b06d-27d01a399604',
          activityTitle: 'Engagement Aproved',
          activityMeta: '45 min ago',
          activityContent: 'Engagement approved and transitioned to Active',
          activityColorClass: 'success',
          activityCreatedBy: {
            userId: 1,
            userFullName: 'Galen Slixby',
            userAvatar: '',
            userTitle: 'Law Firm Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: null
        },
        {
          activityId: '03d1cbd5-18a1-4760-9ed3-33dca45e2477',
          activityTitle: 'Quote Uploaded',
          activityMeta: '45 min ago',
          activityContent: undefined,
          activityColorClass: 'warning',
          activityCreatedBy: {
            userId: 20000,
            userFullName: 'Luis Mego',
            userAvatar: '',
            userTitle: 'DueCourse Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: {
            filename: 'Quote #02345.pdf',
            fileUrl: 'https://invoice.com'
          }
        },
        {
          activityId: '7b81c7e5-a6de-43f0-9321-3e8eab85a854',
          activityTitle: 'Engagement Request Created',
          activityMeta: '3 day ago',
          activityContent: 'Created initial Engagement Request',
          activityColorClass: 'danger',
          activityCreatedBy: {
            userId: 10,
            userFullName: 'Jillene Bellany',
            userAvatar: require('@src/assets/images/avatars/9.png').default,
            userTitle: null
          },
          activityCreatedOn: '07/20/2021',
          attachment: null
        }
      ],
      experienceLevels: [{ id: 'CEL0001', experienceLevelName: 'Junior Associate' }],
      modalities: [{ id: 'CM00002', modalityName: 'Virtual' }],
      instructionTypes: [
        { id: 'CI00001', instructionTypeName: '1 to 1' },
        { id: 'CI00005', instructionTypeName: 'Offsites / Retreats' }
      ],
      languages: [{ id: 'English', languageName: 'English' }],
      coachingSpecialties: [
        { coachingSpecialtyId: 'CS00001', coachingSpecialtyName: 'Business Development' },
        { coachingSpecialtyId: 'CS00020', coachingSpecialtyName: 'Wellness and Well-being' }
      ]
    },
    {
      id: 10003,
      orgId: 10000000,
      title: 'Cras nec mauris in nunc euismod varius',
      description: 'In vitae nulla nunc. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer tempor lorem leo, nec blandit nisl mattis iaculis. Phasellus sit amet elementum odio. Mauris ornare elit sodales risus suscipit, in auctor nisl ullamcorper. Donec eu porta nisl, sit amet sodales sapien. Fusce et ante nibh. Nulla accumsan arcu auctor, dapibus purus at, mollis ex.',
      requestedOn: '07-21-21',
      tentativeStartDate: '2021-08-12',
      orgOffice: { orgOfficeId: '100001', orgOfficeName: 'Austin Office' },
      orgPracticeGroup: { orgPracticeGroupId: '200001', orgPracticeGroupName: 'Corporate Law' },
      requestedBy: {
        userId: 11,
        userFullName: 'Jonah Wharlton',
        userAvatar: require('@src/assets/images/avatars/4.png').default
      },
      coachees: [
        {
          userId: 11,
          userFullName: 'Jonah Wharlton',
          userAvatar: require('@src/assets/images/avatars/4.png').default
        }
      ],
      coaches: [
        {
          coachId: 5,
          coachName: 'Daniel Lucido',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default
        },
        {
          coachId: 8,
          coachName: 'Andy Johnson',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default
        }
      ],
      selectedCoach: {
        coachId: 8,
        coachName: 'Andy Johnson',
        coachAvatar: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default
      },
      currentStatus: { statusId: 'completed', statusName: 'Completed' },
      statusHistory: [
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' }
      ],
      changeHistory: [
        {
          activityId: 'ae9121c6-5090-4da0-aa41-979ca833f374',
          activityTitle: 'Engagement completed',
          activityMeta: '12 min ago',
          activityContent: undefined,
          activityColorClass: 'primary',
          activityCreatedBy: {
            userId: 1,
            userFullName: 'Galen Slixby',
            userAvatar: '',
            userTitle: 'Law Firm Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: undefined
        },
        {
          activityId: '17c9928d-6803-48bf-b06d-27d01a399604',
          activityTitle: 'Engagement Aproved',
          activityMeta: '45 min ago',
          activityContent: 'Engagement approved and transitioned to Active',
          activityColorClass: 'success',
          activityCreatedBy: {
            userId: 1,
            userFullName: 'Galen Slixby',
            userAvatar: '',
            userTitle: 'Law Firm Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: null
        },
        {
          activityId: '03d1cbd5-18a1-4760-9ed3-33dca45e2477',
          activityTitle: 'Quote Uploaded',
          activityMeta: '45 min ago',
          activityContent: undefined,
          activityColorClass: 'warning',
          activityCreatedBy: {
            userId: 1231231,
            userFullName: 'Drew Amoroso',
            userAvatar: '',
            userTitle: 'DueCourse Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: {
            filename: 'Quote #01234.pdf',
            fileUrl: 'https://invoice.com'
          }
        },
        {
          activityId: '7b81c7e5-a6de-43f0-9321-3e8eab85a854',
          activityTitle: 'Engagement Request Created',
          activityMeta: '3 day ago',
          activityContent: 'Created initial Engagement Request',
          activityColorClass: 'danger',
          activityCreatedBy: {
            userId: 11,
            userFullName: 'Jonah Wharlton',
            userAvatar: require('@src/assets/images/avatars/4.png').default,
            userTitle: null
          },
          activityCreatedOn: '07/20/2021',
          attachment: null
        }
      ],
      experienceLevels: [
        { id: 'CEL0001', experienceLevelName: 'Junior Associate' },
        { id: 'CEL0005', experienceLevelName: 'Lateral' }
      ],
      modalities: [{ id: 'CM00002', modalityName: 'Virtual' }],
      instructionTypes: [
        { id: 'CI00001', instructionTypeName: '1 to 1' },
        { id: 'CI00002', instructionTypeName: 'Small Group (2 - 10)' }
      ],
      languages: [{ id: 'English', languageName: 'English' }],
      coachingSpecialties: [
        { coachingSpecialtyId: 'CS00001', coachingSpecialtyName: 'Business Development' },
        { coachingSpecialtyId: 'CS00002', coachingSpecialtyName: 'Coaching and Mentoring' },
        { coachingSpecialtyId: 'CS00003', coachingSpecialtyName: 'Communication' },
        { coachingSpecialtyId: 'CS00019', coachingSpecialtyName: 'Time Management' }
      ]
    },
    {
      id: 10004,
      orgId: 10000000,
      title: 'Curabitur accumsan felis sed erat sagittis tincidunt',
      description: 'Nulla a laoreet nibh. Nunc justo ex, suscipit rhoncus commodo vel, tempor et diam. Sed lobortis diam in malesuada pellentesque. Nunc luctus feugiat leo. Phasellus rutrum vulputate sem. Cras enim lectus, dignissim nec euismod ac, accumsan sit amet urna. Fusce vel leo ac ex maximus porta nec id diam. Donec volutpat tristique nisl at auctor. Sed a lacinia sem, et auctor sapien. Vestibulum sollicitudin ut orci eu ultricies. Curabitur rhoncus nibh a orci faucibus luctus. Sed dignissim maximus posuere.      ',
      requestedOn: '07-21-21',
      tentativeStartDate: '2021-08-12',
      orgOffice: { orgOfficeId: '100001', orgOfficeName: 'Austin Office' },
      orgPracticeGroup: { orgPracticeGroupId: '200001', orgPracticeGroupName: 'Corporate Law' },
      requestedBy: {
        userId: 11,
        userFullName: 'Jonah Wharlton',
        userAvatar: require('@src/assets/images/avatars/4.png').default
      },
      coachees: [
        {
          userId: 8,
          userFullName: 'Kathryne Liger',
          userAvatar: require('@src/assets/images/avatars/9.png').default
        },
        {
          userId: 11,
          userFullName: 'Jonah Wharlton',
          userAvatar: require('@src/assets/images/avatars/4.png').default
        }
      ],
      coaches: [
        {
          coachId: 5,
          coachName: 'Daniel Lucido',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default
        },
        {
          coachId: 6,
          coachName: 'Laura Chavis',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default
        },
        {
          coachId: 7,
          coachName: 'Jakoba Franzen',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default
        }
      ],
      selectedCoach: null,
      currentStatus: { statusId: 'pending', statusName: 'Pending' },
      statusHistory: [
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' }
      ],
      changeHistory: [
        {
          activityId: '03d1cbd5-18a1-4760-9ed3-33dca45e2477',
          activityTitle: 'Quote Uploaded',
          activityMeta: '45 min ago',
          activityContent: undefined,
          activityColorClass: 'warning',
          activityCreatedBy: {
            userId: 1231231,
            userFullName: 'Drew Amoroso',
            userAvatar: '',
            userTitle: 'DueCourse Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: {
            filename: 'Quote #41234.pdf',
            fileUrl: 'https://invoice.com'
          }
        },
        {
          activityId: '7b81c7e5-a6de-43f0-9321-3e8eab85a854',
          activityTitle: 'Engagement Request Created',
          activityMeta: '3 day ago',
          activityContent: 'Created initial Engagement Request',
          activityColorClass: 'danger',
          activityCreatedBy: {
            userId: 11,
            userFullName: 'Jonah Wharlton',
            userAvatar: require('@src/assets/images/avatars/4.png').default,
            userTitle: null
          },
          activityCreatedOn: '07/20/2021',
          attachment: null
        }
      ],
      experienceLevels: [{ id: 'CEL0004', experienceLevelName: 'Partner / Executive' }],
      modalities: [
        { id: 'CM00001', modalityName: 'In person' },
        { id: 'CM00002', modalityName: 'Virtual' },
        { id: 'CM00003', modalityName: 'Willing to Travel' }
      ],
      instructionTypes: [{ id: 'CI00003', instructionTypeName: 'Large Group (11 +)' }],
      languages: [{ id: 'English', languageName: 'English' }],
      coachingSpecialties: [
        { coachingSpecialtyId: 'CS00001', coachingSpecialtyName: 'Business Development' },
        { coachingSpecialtyId: 'CS00002', coachingSpecialtyName: 'Coaching and Mentoring' },
        { coachingSpecialtyId: 'CS00003', coachingSpecialtyName: 'Communication' },
        { coachingSpecialtyId: 'CS00015', coachingSpecialtyName: 'Practice Management' },
        { coachingSpecialtyId: 'CS00016', coachingSpecialtyName: 'Presentation Skills' },
        { coachingSpecialtyId: 'CS00017', coachingSpecialtyName: 'Resilience' }
      ]
    },
    {
      id: 10005,
      orgId: 10000000,
      title: 'Aliquam eu justo viverra, tincidunt dolor et, dignissim nibh',
      description: 'Sed vel hendrerit sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel finibus neque. Suspendisse vitae tellus iaculis, accumsan magna bibendum, congue ex. Duis ut facilisis risus. Aliquam euismod tortor dolor, nec pretium orci tristique at. Morbi rhoncus dolor at leo ornare, quis feugiat lectus iaculis. Nam efficitur, ex auctor placerat tempor, massa turpis viverra purus, vel pulvinar nisl sem sed mi.      ',
      requestedOn: '07-21-21',
      tentativeStartDate: '2021-08-12',
      orgOffice: { orgOfficeId: '100001', orgOfficeName: 'Austin Office' },
      orgPracticeGroup: { orgPracticeGroupId: '200001', orgPracticeGroupName: 'Corporate Law' },
      requestedBy: {
        userId: 8,
        userFullName: 'Kathryne Liger',
        userAvatar: require('@src/assets/images/avatars/9.png').default
      },
      coachees: [
        {
          userId: 2,
          userFullName: 'Halsey Redmore',
          userAvatar: require('@src/assets/images/avatars/10.png').default
        },
        {
          userId: 3,
          userFullName: 'Marjory Sicely',
          userAvatar: require('@src/assets/images/avatars/1.png').default
        },
        {
          userId: 8,
          userFullName: 'Kathryne Liger',
          userAvatar: require('@src/assets/images/avatars/9.png').default
        }
      ],
      coaches: [
        {
          coachId: 11,
          coachName: 'Ruth Wilkinson',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default
        }
      ],
      selectedCoach: null,
      currentStatus: { statusId: 'pending', statusName: 'Pending' },
      statusHistory: [
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' }
      ],
      changeHistory: [
        {
          activityId: '03d1cbd5-18a1-4760-9ed3-33dca45e2477',
          activityTitle: 'Quote Uploaded',
          activityMeta: '45 min ago',
          activityContent: undefined,
          activityColorClass: 'warning',
          activityCreatedBy: {
            userId: 1231231,
            userFullName: 'Drew Amoroso',
            userAvatar: '',
            userTitle: 'DueCourse Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: {
            filename: 'Quote #01234.pdf',
            fileUrl: 'https://invoice.com'
          }
        },
        {
          activityId: '7b81c7e5-a6de-43f0-9321-3e8eab85a854',
          activityTitle: 'Engagement Request Created',
          activityMeta: '3 day ago',
          activityContent: 'Created initial Engagement Request',
          activityColorClass: 'danger',
          activityCreatedBy: {
            userId: 8,
            userFullName: 'Kathryne Liger',
            userAvatar: require('@src/assets/images/avatars/9.png').default,
            userTitle: null
          },
          activityCreatedOn: '07/20/2021',
          attachment: null
        }
      ],
      experienceLevels: [
        { id: 'CEL0001', experienceLevelName: 'Junior Associate' },
        { id: 'CEL0005', experienceLevelName: 'Lateral' },
        { id: 'CEL0006', experienceLevelName: 'Professional Staff' }
      ],
      modalities: [
        { id: 'CM00001', modalityName: 'In person' },
        { id: 'CM00003', modalityName: 'Willing to Travel' }
      ],
      instructionTypes: [
        { id: 'CI00001', instructionTypeName: '1 to 1' },
        { id: 'CI00005', instructionTypeName: 'Offsites / Retreats' }
      ],
      languages: [
        { id: 'English', languageName: 'English' },
        { id: 'French', languageName: 'French' },
        { id: 'Spanish', languageName: 'Spanish' }
      ],
      coachingSpecialties: [
        { coachingSpecialtyId: 'CS00001', coachingSpecialtyName: 'Business Development' },
        { coachingSpecialtyId: 'CS00002', coachingSpecialtyName: 'Coaching and Mentoring' },
        { coachingSpecialtyId: 'CS00021', coachingSpecialtyName: 'Womens Empowerment' }
      ]
    },
    {
      id: 10006,
      orgId: 10000000,
      title: 'Fusce venenatis justo eget erat sodales interdum',
      description: 'Maecenas nisl massa, ornare at nulla quis, ultricies blandit orci. Donec quis dui in tellus ultrices sollicitudin. Phasellus sodales ultricies nunc vitae pharetra. Proin nec laoreet eros. Duis rhoncus varius felis, dictum facilisis urna aliquam id. Nunc vitae suscipit purus. Vestibulum accumsan venenatis dui, at luctus ante condimentum nec. Donec non pellentesque velit, sit amet mattis sapien. Etiam tincidunt placerat leo, a pretium odio accumsan consequat. Duis in hendrerit massa, eget faucibus nibh. Nullam cursus erat in accumsan pharetra. Aenean tristique sem leo, quis elementum tellus accumsan a.',
      requestedOn: '07-22-21',
      tentativeStartDate: '2021-08-12',
      orgOffice: { orgOfficeId: '100001', orgOfficeName: 'Austin Office' },
      orgPracticeGroup: { orgPracticeGroupId: '200001', orgPracticeGroupName: 'Corporate Law' },
      requestedBy: {
        userId: 2,
        userFullName: 'Halsey Redmore',
        userAvatar: require('@src/assets/images/avatars/10.png').default
      },
      coachees: [
        {
          userId: 1,
          userFullName: 'Galen Slixby',
          userAvatar: ''
        },
        {
          userId: 2,
          userFullName: 'Halsey Redmore',
          userAvatar: require('@src/assets/images/avatars/10.png').default
        },
        {
          userId: 5,
          userFullName: 'Max Hurran',
          userAvatar: require('@src/assets/images/avatars/10.png').default
        },
        {
          userId: 6,
          userFullName: 'Silvain Halstead',
          userAvatar: ''
        }
      ],
      coaches: [
        {
          coachId: 10,
          coachName: 'Danielle Kasch',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-10.jpg').default
        },
        {
          coachId: 11,
          coachName: 'Ruth Wilkinson',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default
        }
      ],
      selectedCoach: {
        coachId: 11,
        coachName: 'Ruth Wilkinson',
        coachAvatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default
      },
      currentStatus: { statusId: 'active', statusName: 'Active' },
      statusHistory: [
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' }
      ],
      changeHistory: [
        {
          activityId: '17c9928d-6803-48bf-b06d-27d01a399604',
          activityTitle: 'Engagement Aproved',
          activityMeta: '45 min ago',
          activityContent: 'Engagement approved and transitioned to Active',
          activityColorClass: 'success',
          activityCreatedBy: {
            userId: 1,
            userFullName: 'Galen Slixby',
            userAvatar: '',
            userTitle: 'Law Firm Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: null
        },
        {
          activityId: '03d1cbd5-18a1-4760-9ed3-33dca45e2477',
          activityTitle: 'Quote Uploaded',
          activityMeta: '45 min ago',
          activityContent: undefined,
          activityColorClass: 'warning',
          activityCreatedBy: {
            userId: 1231231,
            userFullName: 'Drew Amoroso',
            userAvatar: '',
            userTitle: 'DueCourse Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: {
            filename: 'Quote #01234.pdf',
            fileUrl: 'https://invoice.com'
          }
        },
        {
          activityId: '20bea752-b0c7-49cc-8dc5-77b5fcf9c5a0',
          activityTitle: 'Engagement Request Modified',
          activityMeta: '2 day ago',
          activityContent: 'Add details to Engagement',
          activityColorClass: 'warning',
          activityCreatedBy: {
            userId: 2,
            userFullName: 'Halsey Redmore',
            userAvatar: require('@src/assets/images/avatars/10.png').default,
            userTitle: null
          },
          activityCreatedOn: '07/21/2021',
          attachment: null
        },
        {
          activityId: '7b81c7e5-a6de-43f0-9321-3e8eab85a854',
          activityTitle: 'Engagement Request Created',
          activityMeta: '3 day ago',
          activityContent: 'Created initial Engagement Request',
          activityColorClass: 'danger',
          activityCreatedBy: {
            userId: 2,
            userFullName: 'Halsey Redmore',
            userAvatar: require('@src/assets/images/avatars/10.png').default,
            userTitle: null
          },
          activityCreatedOn: '07/20/2021',
          attachment: null
        }
      ],
      experienceLevels: [
        { id: 'CEL0002', experienceLevelName: 'Mid-Level Associate' },
        { id: 'CEL0006', experienceLevelName: 'Professional Staff' }
      ],
      modalities: [
        { id: 'CM00001', modalityName: 'In person' },
        { id: 'CM00003', modalityName: 'Willing to Travel' }
      ],
      instructionTypes: [
        { id: 'CI00004', instructionTypeName: 'Workshops / Presentations' },
        { id: 'CI00005', instructionTypeName: 'Offsites / Retreats' }
      ],
      languages: [
        { id: 'English', languageName: 'English' },
        { id: 'French', languageName: 'French' }
      ],
      coachingSpecialties: [
        { coachingSpecialtyId: 'CS00020', coachingSpecialtyName: 'Wellness and Well-being' },
        { coachingSpecialtyId: 'CS00021', coachingSpecialtyName: 'Womens Empowerment' }
      ]
    },
    {
      id: 10007,
      orgId: 10000000,
      title: 'Curabitur nec lacus volutpat, cursus lorem facilisis, aliquet ex',
      description: 'Sed gravida sapien quis urna pharetra elementum. In feugiat, nisl sit amet tincidunt lobortis, ligula sapien condimentum enim, eu tincidunt mi enim nec orci. Duis sit amet ex odio. Proin a blandit libero, id faucibus purus. Phasellus volutpat dolor finibus lorem consectetur, a feugiat ex mattis. Suspendisse posuere, sapien non convallis vestibulum, nulla nisl elementum dolor, eu vehicula arcu elit quis lectus. Curabitur dignissim felis velit, fringilla elementum tortor feugiat nec. Sed accumsan ante ac blandit interdum. Sed eu scelerisque elit. Aenean eu condimentum metus, et rhoncus mi. Vestibulum tincidunt libero a elit ornare ullamcorper.',
      requestedOn: '07-22-21',
      tentativeStartDate: '2021-08-12',
      orgOffice: { orgOfficeId: '100001', orgOfficeName: 'Austin Office' },
      orgPracticeGroup: { orgPracticeGroupId: '200001', orgPracticeGroupName: 'Corporate Law' },
      requestedBy: {
        userId: 2,
        userFullName: 'Halsey Redmore',
        userAvatar: require('@src/assets/images/avatars/10.png').default
      },
      coachees: [
        {
          userId: 10,
          userFullName: 'Jillene Bellany',
          userAvatar: require('@src/assets/images/avatars/9.png').default
        }
      ],
      coaches: [
        {
          coachId: 11,
          coachName: 'Ruth Wilkinson',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default
        }
      ],
      selectedCoach: null,
      currentStatus: { statusId: 'pending', statusName: 'Pending' },
      statusHistory: [
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' }
      ],
      changeHistory: [
        {
          activityId: '03d1cbd5-18a1-4760-9ed3-33dca45e2477',
          activityTitle: 'Quote Uploaded',
          activityMeta: '45 min ago',
          activityContent: undefined,
          activityColorClass: 'warning',
          activityCreatedBy: {
            userId: 1231231,
            userFullName: 'Drew Amoroso',
            userAvatar: '',
            userTitle: 'DueCourse Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: {
            filename: 'Quote #01234.pdf',
            fileUrl: 'https://invoice.com'
          }
        },
        {
          activityId: '7b81c7e5-a6de-43f0-9321-3e8eab85a854',
          activityTitle: 'Engagement Request Created',
          activityMeta: '3 day ago',
          activityContent: 'Created initial Engagement Request',
          activityColorClass: 'danger',
          activityCreatedBy: {
            userId: 2,
            userFullName: 'Halsey Redmore',
            userAvatar: require('@src/assets/images/avatars/10.png').default,
            userTitle: null
          },
          activityCreatedOn: '07/20/2021',
          attachment: null
        }
      ],
      experienceLevels: [
        { id: 'CEL0001', experienceLevelName: 'Junior Associate' },
        { id: 'CEL0002', experienceLevelName: 'Mid-Level Associate' },
        { id: 'CEL0004', experienceLevelName: 'Partner / Executive' },
        { id: 'CEL0005', experienceLevelName: 'Lateral' }
      ],
      modalities: [
        { id: 'CM00001', modalityName: 'In person' },
        { id: 'CM00003', modalityName: 'Willing to Travel' }
      ],
      instructionTypes: [
        { id: 'CI00001', instructionTypeName: '1 to 1' },
        { id: 'CI00002', instructionTypeName: 'Small Group (2 - 10)' },
        { id: 'CI00003', instructionTypeName: 'Large Group (11 +)' },
        { id: 'CI00004', instructionTypeName: 'Workshops / Presentations' }
      ],
      languages: [{ id: 'English', languageName: 'English' }],
      coachingSpecialties: [
        { coachingSpecialtyId: 'CS00001', coachingSpecialtyName: 'Business Development' },
        { coachingSpecialtyId: 'CS00015', coachingSpecialtyName: 'Practice Management' },
        { coachingSpecialtyId: 'CS00016', coachingSpecialtyName: 'Presentation Skills' },
        { coachingSpecialtyId: 'CS00017', coachingSpecialtyName: 'Resilience' }
      ]
    },
    {
      id: 10008,
      orgId: 10000000,
      title: 'Donec eget lacus quis eros tempus viverra',
      description: 'Vestibulum volutpat, tortor eget malesuada feugiat, odio diam lobortis erat, at pellentesque nulla quam eget odio. Nunc id dolor arcu. Vivamus id dignissim erat. Vivamus sagittis placerat leo sit amet tincidunt. Etiam at molestie purus. Suspendisse non elit et diam porta vestibulum. Duis blandit dapibus tristique. Nulla faucibus nunc at elit aliquet, at condimentum tortor posuere. Nullam bibendum pellentesque risus. Duis non feugiat augue. Phasellus non mi sed enim mollis lobortis. Praesent tincidunt magna nec magna dictum ullamcorper.',
      requestedOn: '07-23-21',
      tentativeStartDate: '2021-08-12',
      orgOffice: { orgOfficeId: '100001', orgOfficeName: 'Austin Office' },
      orgPracticeGroup: { orgPracticeGroupId: '200002', orgPracticeGroupName: 'Litigation and Investigations' },
      requestedBy: {
        userId: 2,
        userFullName: 'Halsey Redmore',
        userAvatar: require('@src/assets/images/avatars/10.png').default
      },
      coachees: [
        {
          userId: 10,
          userFullName: 'Jillene Bellany',
          userAvatar: require('@src/assets/images/avatars/9.png').default
        },
        {
          userId: 11,
          userFullName: 'Jonah Wharlton',
          userAvatar: require('@src/assets/images/avatars/4.png').default
        }
      ],
      coaches: [
        {
          coachId: 9,
          coachName: 'Scott Brown',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default
        },
        {
          coachId: 10,
          coachName: 'Danielle Kasch',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-10.jpg').default
        },
        {
          coachId: 11,
          coachName: 'Ruth Wilkinson',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default
        }
      ],
      selectedCoach: {
        coachId: 11,
        coachName: 'Ruth Wilkinson',
        coachAvatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default
      },
      currentStatus: { statusId: 'active', statusName: 'Active' },
      statusHistory: [
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' }
      ],
      changeHistory: [
        {
          activityId: '17c9928d-6803-48bf-b06d-27d01a399604',
          activityTitle: 'Engagement Aproved',
          activityMeta: '45 min ago',
          activityContent: 'Engagement approved and transitioned to Active',
          activityColorClass: 'success',
          activityCreatedBy: {
            userId: 1,
            userFullName: 'Galen Slixby',
            userAvatar: '',
            userTitle: 'Law Firm Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: null
        },
        {
          activityId: '03d1cbd5-18a1-4760-9ed3-33dca45e2477',
          activityTitle: 'Quote Uploaded',
          activityMeta: '45 min ago',
          activityContent: undefined,
          activityColorClass: 'warning',
          activityCreatedBy: {
            userId: 1231231,
            userFullName: 'Drew Amoroso',
            userAvatar: '',
            userTitle: 'DueCourse Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: {
            filename: 'Quote #01234.pdf',
            fileUrl: 'https://invoice.com'
          }
        },
        {
          activityId: '20bea752-b0c7-49cc-8dc5-77b5fcf9c5a0',
          activityTitle: 'Engagement Request Modified',
          activityMeta: '2 day ago',
          activityContent: 'Add details to Engagement',
          activityColorClass: 'warning',
          activityCreatedBy: {
            userId: 2,
            userFullName: 'Halsey Redmore',
            userAvatar: require('@src/assets/images/avatars/10.png').default,
            userTitle: null
          },
          activityCreatedOn: '07/21/2021',
          attachment: null
        },
        {
          activityId: '7b81c7e5-a6de-43f0-9321-3e8eab85a854',
          activityTitle: 'Engagement Request Created',
          activityMeta: '3 day ago',
          activityContent: 'Created initial Engagement Request',
          activityColorClass: 'danger',
          activityCreatedBy: {
            userId: 2,
            userFullName: 'Halsey Redmore',
            userAvatar: require('@src/assets/images/avatars/10.png').default,
            userTitle: null
          },
          activityCreatedOn: '07/20/2021',
          attachment: null
        }
      ],
      experienceLevels: [{ id: 'CEL0002', experienceLevelName: 'Mid-Level Associate' }],
      modalities: [{ id: 'CM00002', modalityName: 'Virtual' }],
      instructionTypes: [{ id: 'CI00001', instructionTypeName: '1 to 1' }],
      languages: [
        { id: 'French', languageName: 'French' },
        { id: 'Spanish', languageName: 'Spanish' }
      ],
      coachingSpecialties: [
        { coachingSpecialtyId: 'CS00001', coachingSpecialtyName: 'Business Development' },
        { coachingSpecialtyId: 'CS00002', coachingSpecialtyName: 'Coaching and Mentoring' },
        { coachingSpecialtyId: 'CS00003', coachingSpecialtyName: 'Communication' }
      ]
    },
    {
      id: 10009,
      orgId: 10000000,
      title: 'Vestibulum ut dolor finibus, fermentum ipsum quis, mollis quam',
      description: 'Vestibulum porta ut eros ut placerat. Maecenas vel ornare quam, sit amet mollis ante. Mauris molestie vel nibh a vehicula. Sed facilisis lectus nec tellus placerat fermentum. Morbi faucibus justo quam, vitae luctus tortor finibus ut. Nam vehicula rhoncus nunc ac fringilla. Mauris tincidunt odio justo, a mattis quam vulputate sit amet. Sed quis convallis mi, quis elementum diam. Maecenas vitae ornare massa. Proin mi felis, dapibus vel maximus non, rutrum nec turpis. Maecenas ligula orci, ornare ornare leo quis, porta tincidunt velit. Sed at quam porttitor nunc rhoncus porta non eu sem. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.',
      requestedOn: '07-23-21',
      tentativeStartDate: '2021-08-12',
      orgOffice: { orgOfficeId: '100001', orgOfficeName: 'Austin Office' },
      orgPracticeGroup: { orgPracticeGroupId: '200001', orgPracticeGroupName: 'Corporate Law' },
      requestedBy: {
        userId: 2,
        userFullName: 'Halsey Redmore',
        userAvatar: require('@src/assets/images/avatars/10.png').default
      },
      coachees: [
        {
          userId: 8,
          userFullName: 'Kathryne Liger',
          userAvatar: require('@src/assets/images/avatars/9.png').default
        }
      ],
      coaches: [
        {
          coachId: 8,
          coachName: 'Andy Johnson',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default
        },
        {
          coachId: 9,
          coachName: 'Scott Brown',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default
        }
      ],
      selectedCoach: {
        coachId: 9,
        coachName: 'Scott Brown',
        coachAvatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default
      },
      currentStatus: { statusId: 'completed', statusName: 'Completed' },
      statusHistory: [
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' }
      ],
      changeHistory: [
        {
          activityId: 'ae9121c6-5090-4da0-aa41-979ca833f374',
          activityTitle: 'Engagement completed',
          activityMeta: '12 min ago',
          activityContent: undefined,
          activityColorClass: 'primary',
          activityCreatedBy: {
            userId: 1,
            userFullName: 'Galen Slixby',
            userAvatar: '',
            userTitle: 'Law Firm Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: undefined
        },
        {
          activityId: '17c9928d-6803-48bf-b06d-27d01a399604',
          activityTitle: 'Engagement Aproved',
          activityMeta: '45 min ago',
          activityContent: 'Engagement approved and transitioned to Active',
          activityColorClass: 'success',
          activityCreatedBy: {
            userId: 1,
            userFullName: 'Galen Slixby',
            userAvatar: '',
            userTitle: 'Law Firm Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: null
        },
        {
          activityId: '03d1cbd5-18a1-4760-9ed3-33dca45e2477',
          activityTitle: 'Quote Uploaded',
          activityMeta: '45 min ago',
          activityContent: undefined,
          activityColorClass: 'warning',
          activityCreatedBy: {
            userId: 1231231,
            userFullName: 'Drew Amoroso',
            userAvatar: '',
            userTitle: 'DueCourse Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: {
            filename: 'Quote #01234.pdf',
            fileUrl: 'https://invoice.com'
          }
        },
        {
          activityId: '7b81c7e5-a6de-43f0-9321-3e8eab85a854',
          activityTitle: 'Engagement Request Created',
          activityMeta: '3 day ago',
          activityContent: 'Created initial Engagement Request',
          activityColorClass: 'danger',
          activityCreatedBy: {
            userId: 2,
            userFullName: 'Halsey Redmore',
            userAvatar: require('@src/assets/images/avatars/10.png').default,
            userTitle: null
          },
          activityCreatedOn: '07/20/2021',
          attachment: null
        }
      ],
      experienceLevels: [{ id: 'CEL0001', experienceLevelName: 'Junior Associate' }],
      modalities: [
        { id: 'CM00001', modalityName: 'In person' },
        { id: 'CM00002', modalityName: 'Virtual' },
        { id: 'CM00003', modalityName: 'Willing to Travel' }
      ],
      instructionTypes: [{ id: 'CI00001', instructionTypeName: '1 to 1' }],
      languages: [
        { id: 'English', languageName: 'English' },
        { id: 'French', languageName: 'French' },
        { id: 'Spanish', languageName: 'Spanish' }
      ],
      coachingSpecialties: [
        { coachingSpecialtyId: 'CS00001', coachingSpecialtyName: 'Business Development' },
        { coachingSpecialtyId: 'CS00002', coachingSpecialtyName: 'Coaching and Mentoring' },
        { coachingSpecialtyId: 'CS00016', coachingSpecialtyName: 'Presentation Skills' },
        { coachingSpecialtyId: 'CS00017', coachingSpecialtyName: 'Resilience' }
      ]
    },
    {
      id: 10010,
      orgId: 10000000,
      title: 'Sed aliquet turpis in vestibulum commodo',
      description: 'Donec sit amet fringilla urna. Mauris et commodo felis, a fringilla purus. Sed vestibulum venenatis sodales. Proin vitae libero sit amet ex posuere placerat. Donec sem dolor, egestas at neque a, porttitor commodo lectus. Donec tincidunt sagittis auctor. Proin tempus massa eget pretium finibus. Sed pulvinar neque dolor, nec viverra diam ornare in. Morbi et finibus leo, sed consequat dui. Vestibulum leo dolor, eleifend in eros eget, tincidunt cursus mi. Aliquam tincidunt lacus non scelerisque iaculis.',
      requestedOn: '07-23-21',
      tentativeStartDate: '2021-08-12',
      orgOffice: { orgOfficeId: '100001', orgOfficeName: 'Austin Office' },
      orgPracticeGroup: { orgPracticeGroupId: '200001', orgPracticeGroupName: 'Corporate Law' },
      requestedBy: {
        userId: 3,
        userFullName: 'Marjory Sicely',
        userAvatar: require('@src/assets/images/avatars/1.png').default
      },
      coachees: [
        {
          userId: 3,
          userFullName: 'Marjory Sicely',
          userAvatar: require('@src/assets/images/avatars/1.png').default
        }
      ],
      coaches: [
        {
          coachId: 5,
          coachName: 'Daniel Lucido',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default
        },
        {
          coachId: 6,
          coachName: 'Laura Chavis',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default
        }
      ],
      selectedCoach: {
        coachId: 6,
        coachName: 'Laura Chavis',
        coachAvatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default
      },
      currentStatus: { statusId: 'completed', statusName: 'Completed' },
      orgOffice: { orgOfficeId: '100001', orgOfficeName: 'Austin Office' },
      orgPracticeGroup: { orgPracticeGroupId: '200001', orgPracticeGroupName: 'Corporate Law' },
      statusHistory: [
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' }
      ],
      changeHistory: [
        {
          activityId: 'ae9121c6-5090-4da0-aa41-979ca833f374',
          activityTitle: 'Engagement completed',
          activityMeta: '12 min ago',
          activityContent: undefined,
          activityColorClass: 'primary',
          activityCreatedBy: {
            userId: 1,
            userFullName: 'Galen Slixby',
            userAvatar: '',
            userTitle: 'Law Firm Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: undefined
        },
        {
          activityId: '17c9928d-6803-48bf-b06d-27d01a399604',
          activityTitle: 'Engagement Aproved',
          activityMeta: '45 min ago',
          activityContent: 'Engagement approved and transitioned to Active',
          activityColorClass: 'success',
          activityCreatedBy: {
            userId: 1,
            userFullName: 'Galen Slixby',
            userAvatar: '',
            userTitle: 'Law Firm Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: null
        },
        {
          activityId: '03d1cbd5-18a1-4760-9ed3-33dca45e2477',
          activityTitle: 'Quote Uploaded',
          activityMeta: '45 min ago',
          activityContent: undefined,
          activityColorClass: 'warning',
          activityCreatedBy: {
            userId: 1231231,
            userFullName: 'Drew Amoroso',
            userAvatar: '',
            userTitle: 'DueCourse Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: {
            filename: 'Quote #01234.pdf',
            fileUrl: 'https://invoice.com'
          }
        },
        {
          activityId: '7b81c7e5-a6de-43f0-9321-3e8eab85a854',
          activityTitle: 'Engagement Request Created',
          activityMeta: '3 day ago',
          activityContent: 'Created initial Engagement Request',
          activityColorClass: 'danger',
          activityCreatedBy: {
            userId: 3,
            userFullName: 'Marjory Sicely',
            userAvatar: require('@src/assets/images/avatars/1.png').default,
            userTitle: null
          },
          activityCreatedOn: '07/20/2021',
          attachment: null
        }
      ],
      experienceLevels: [{ id: 'CEL0001', experienceLevelName: 'Junior Associate' }],
      modalities: [
        { id: 'CM00001', modalityName: 'In person' },
        { id: 'CM00002', modalityName: 'Virtual' }
      ],
      instructionTypes: [
        { id: 'CI00001', instructionTypeName: '1 to 1' },
        { id: 'CI00002', instructionTypeName: 'Small Group (2 - 10)' },
        { id: 'CI00003', instructionTypeName: 'Large Group (11 +)' }
      ],
      languages: [
        { id: 'English', languageName: 'English' },
        { id: 'French', languageName: 'French' },
        { id: 'Spanish', languageName: 'Spanish' }
      ],
      coachingSpecialties: [
        { coachingSpecialtyId: 'CS00019', coachingSpecialtyName: 'Time Management' },
        { coachingSpecialtyId: 'CS00020', coachingSpecialtyName: 'Wellness and Well-being' }
      ]
    },
    {
      id: 10011,
      orgId: 10000000,
      title: 'Sed non augue a sapien blandit porttitor eu sit amet risus',
      description: 'Phasellus eget leo sapien. Sed nec justo id risus laoreet aliquet et in nibh. Phasellus porttitor, enim at hendrerit fringilla, dui justo volutpat mauris, nec vehicula sem ex vitae enim. Pellentesque ullamcorper et purus id hendrerit. Vivamus eros elit, maximus sit amet pretium quis, tempus at neque. Nam a scelerisque magna. Fusce vitae posuere tortor. Donec id sodales felis, nec imperdiet ipsum. In vel nibh felis. Etiam maximus non sem nec scelerisque.',
      requestedOn: '07-25-21',
      tentativeStartDate: '2021-08-12',
      orgOffice: { orgOfficeId: '100001', orgOfficeName: 'Austin Office' },
      orgPracticeGroup: { orgPracticeGroupId: '200001', orgPracticeGroupName: 'Corporate Law' },
      requestedBy: {
        userId: 9,
        userFullName: "Felix Scotfurth",
        userAvatar: require('@src/assets/images/avatars/2.png').default
      },
      coachees: [
        {
          userId: 9,
          userFullName: "Felix Scotfurth",
          userAvatar: require('@src/assets/images/avatars/2.png').default
        }
      ],
      coaches: [
        {
          coachId: 4,
          coachName: 'Neiman Hatcher',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default
        },
        {
          coachId: 7,
          coachName: 'Jakoba Franzen',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default
        }
      ],
      selectedCoach: null,
      currentStatus: { statusId: 'pending', statusName: 'Pending' },
      statusHistory: [
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' }
      ],
      changeHistory: [
        {
          activityId: '20bea752-b0c7-49cc-8dc5-77b5fcf9c5a0',
          activityTitle: 'Engagement Request Modified',
          activityMeta: '2 day ago',
          activityContent: 'Add details to Engagement',
          activityColorClass: 'warning',
          activityCreatedBy: {
            userId: 9,
            userFullName: "Felix Scotfurth",
            userAvatar: require('@src/assets/images/avatars/2.png').default,
            userTitle: null
          },
          activityCreatedOn: '07/21/2021',
          attachment: null
        },
        {
          activityId: '7b81c7e5-a6de-43f0-9321-3e8eab85a854',
          activityTitle: 'Engagement Request Created',
          activityMeta: '3 day ago',
          activityContent: 'Created initial Engagement Request',
          activityColorClass: 'danger',
          activityCreatedBy: {
            userId: 9,
            userFullName: "Felix Scotfurth",
            userAvatar: require('@src/assets/images/avatars/2.png').default,
            userTitle: null
          },
          activityCreatedOn: '07/20/2021',
          attachment: null
        }
      ],
      experienceLevels: [
        { id: 'CEL0002', experienceLevelName: 'Mid-Level Associate' },
        { id: 'CEL0004', experienceLevelName: 'Partner / Executive' }
      ],
      modalities: [
        { id: 'CM00001', modalityName: 'In person' },
        { id: 'CM00003', modalityName: 'Willing to Travel' }
      ],
      instructionTypes: [{ id: 'CI00005', instructionTypeName: 'Offsites / Retreats' }],
      languages: [{ id: 'English', languageName: 'English' }],
      coachingSpecialties: [
        { coachingSpecialtyId: 'CS00012', coachingSpecialtyName: 'Management' },
        { coachingSpecialtyId: 'CS00013', coachingSpecialtyName: 'Mindfulness' }
      ]
    },
    {
      id: 10012,
      orgId: 10000000,
      title: 'Etiam blandit ex at tellus porta pharetra',
      description: 'Maecenas quis dui in sem tempus hendrerit. Curabitur vitae est blandit tellus elementum cursus. Phasellus sit amet neque nec purus imperdiet convallis. Morbi volutpat arcu at odio accumsan pellentesque. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nam accumsan dapibus nisi ut pharetra. Nulla congue viverra eleifend. Sed ultricies enim non purus porta ultricies.',
      requestedOn: '07-25-21',
      tentativeStartDate: '2021-08-12',
      orgOffice: { orgOfficeId: '100001', orgOfficeName: 'Austin Office' },
      orgPracticeGroup: { orgPracticeGroupId: '200001', orgPracticeGroupName: 'Corporate Law' },
      requestedBy: {
        userId: 8,
        userFullName: 'Kathryne Liger',
        userAvatar: require('@src/assets/images/avatars/9.png').default
      },
      coachees: [
        {
          userId: 3,
          userFullName: 'Marjory Sicely',
          userAvatar: require('@src/assets/images/avatars/1.png').default
        },
        {
          userId: 6,
          userFullName: 'Silvain Halstead',
          userAvatar: ''
        },
        {
          userId: 8,
          userFullName: 'Kathryne Liger',
          userAvatar: require('@src/assets/images/avatars/9.png').default
        },
        {
          userId: 11,
          userFullName: 'Jonah Wharlton',
          userAvatar: require('@src/assets/images/avatars/4.png').default
        }
      ],
      coaches: [
        {
          coachId: 4,
          coachName: 'Neiman Hatcher',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default
        },
        {
          coachId: 5,
          coachName: 'Daniel Lucido',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default
        },
        {
          coachId: 6,
          coachName: 'Laura Chavis',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default
        },
        {
          coachId: 7,
          coachName: 'Jakoba Franzen',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default
        }
      ],
      selectedCoach: {
        coachId: 6,
        coachName: 'Laura Chavis',
        coachAvatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default
      },
      currentStatus: { statusId: 'active', statusName: 'Active' },
      statusHistory: [
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' }
      ],
      changeHistory: [
        {
          activityId: '17c9928d-6803-48bf-b06d-27d01a399604',
          activityTitle: 'Engagement Aproved',
          activityMeta: '45 min ago',
          activityContent: 'Engagement approved and transitioned to Active',
          activityColorClass: 'success',
          activityCreatedBy: {
            userId: 1,
            userFullName: 'Galen Slixby',
            userAvatar: '',
            userTitle: 'Law Firm Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: null
        },
        {
          activityId: '03d1cbd5-18a1-4760-9ed3-33dca45e2477',
          activityTitle: 'Quote Uploaded',
          activityMeta: '45 min ago',
          activityContent: undefined,
          activityColorClass: 'warning',
          activityCreatedBy: {
            userId: 1231231,
            userFullName: 'Drew Amoroso',
            userAvatar: '',
            userTitle: 'DueCourse Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: {
            filename: 'Quote #01234.pdf',
            fileUrl: 'https://invoice.com'
          }
        },
        {
          activityId: '7b81c7e5-a6de-43f0-9321-3e8eab85a854',
          activityTitle: 'Engagement Request Created',
          activityMeta: '3 day ago',
          activityContent: 'Created initial Engagement Request',
          activityColorClass: 'danger',
          activityCreatedBy: {
            userId: 8,
            userFullName: 'Kathryne Liger',
            userAvatar: require('@src/assets/images/avatars/9.png').default,
            userTitle: null
          },
          activityCreatedOn: '07/20/2021',
          attachment: null
        }
      ],
      experienceLevels: [
        { id: 'CEL0001', experienceLevelName: 'Junior Associate' },
        { id: 'CEL0005', experienceLevelName: 'Lateral' }
      ],
      modalities: [
        { id: 'CM00001', modalityName: 'In person' },
        { id: 'CM00002', modalityName: 'Virtual' }
      ],
      instructionTypes: [
        { id: 'CI00001', instructionTypeName: '1 to 1' },
        { id: 'CI00002', instructionTypeName: 'Small Group (2 - 10)' },
        { id: 'CI00003', instructionTypeName: 'Large Group (11 +)' }
      ],
      languages: [{ id: 'English', languageName: 'English' }],
      coachingSpecialties: [
        { coachingSpecialtyId: 'CS00001', coachingSpecialtyName: 'Business Development' },
        { coachingSpecialtyId: 'CS00014', coachingSpecialtyName: 'Mindset' }
      ]
    },
    {
      id: 10013,
      orgId: 10000000,
      title: 'usce eu nunc nec augue ultrices feugiat',
      description: 'Donec quis elit magna. Nunc sed nunc a diam scelerisque dignissim. In nec sapien ac ex imperdiet condimentum. Duis pulvinar elit eget tellus viverra facilisis. In at risus diam. Fusce ut urna ut enim laoreet fermentum. Nullam suscipit quam ac egestas tempus. Donec commodo arcu tempor odio sagittis eleifend. Nunc volutpat augue quis libero consequat, eu rhoncus nibh venenatis. Nullam scelerisque varius pellentesque.',
      requestedOn: '07-28-21',
      tentativeStartDate: '2021-08-12',
      orgOffice: { orgOfficeId: '100001', orgOfficeName: 'Austin Office' },
      orgPracticeGroup: { orgPracticeGroupId: '200001', orgPracticeGroupName: 'Corporate Law' },
      requestedBy: {
        userId: 7,
        userFullName: 'Breena Gallemore',
        userAvatar: ''
      },
      coachees: [
        {
          userId: 7,
          userFullName: 'Breena Gallemore',
          userAvatar: ''
        }
      ],
      coaches: [
        {
          coachId: 3,
          coachName: 'Alfreda McCray',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default
        }
      ],
      selectedCoach: {
        coachId: 3,
        coachName: 'Alfreda McCray',
        coachAvatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default
      },
      currentStatus: { statusId: 'active', statusName: 'Active' },
      statusHistory: [
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' }
      ],
      changeHistory: [
        {
          activityId: '17c9928d-6803-48bf-b06d-27d01a399604',
          activityTitle: 'Engagement Aproved',
          activityMeta: '45 min ago',
          activityContent: 'Engagement approved and transitioned to Active',
          activityColorClass: 'success',
          activityCreatedBy: {
            userId: 1,
            userFullName: 'Galen Slixby',
            userAvatar: '',
            userTitle: 'Law Firm Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: null
        },
        {
          activityId: '03d1cbd5-18a1-4760-9ed3-33dca45e2477',
          activityTitle: 'Quote Uploaded',
          activityMeta: '45 min ago',
          activityContent: undefined,
          activityColorClass: 'warning',
          activityCreatedBy: {
            userId: 1231231,
            userFullName: 'Drew Amoroso',
            userAvatar: '',
            userTitle: 'DueCourse Admin'
          },
          activityCreatedOn: '07/22/2021',
          attachment: {
            filename: 'Quote #01234.pdf',
            fileUrl: 'https://invoice.com'
          }
        },
        {
          activityId: '7b81c7e5-a6de-43f0-9321-3e8eab85a854',
          activityTitle: 'Engagement Request Created',
          activityMeta: '3 day ago',
          activityContent: 'Created initial Engagement Request',
          activityColorClass: 'danger',
          activityCreatedBy: {
            userId: 7,
            userFullName: 'Breena Gallemore',
            userAvatar: '',
            userTitle: null
          },
          activityCreatedOn: '07/20/2021',
          attachment: null
        }
      ],
      experienceLevels: [{ id: 'CEL0005', experienceLevelName: 'Lateral' }],
      modalities: [
        { id: 'CM00001', modalityName: 'In person' },
        { id: 'CM00002', modalityName: 'Virtual' },
        { id: 'CM00003', modalityName: 'Willing to Travel' }
      ],
      instructionTypes: [
        { id: 'CI00003', instructionTypeName: 'Large Group (11 +)' },
        { id: 'CI00004', instructionTypeName: 'Workshops / Presentations' }
      ],
      languages: [{ id: 'Spanish', languageName: 'Spanish' }],
      coachingSpecialties: [
        { coachingSpecialtyId: 'CS00015', coachingSpecialtyName: 'Practice Management' },
        { coachingSpecialtyId: 'CS00016', coachingSpecialtyName: 'Presentation Skills' }
      ]
    },
    {
      id: 10014,
      orgId: 10000000,
      title: 'Nullam nec est sed risus imperdiet scelerisque in in felis',
      description: 'Donec quis elit magna. Nunc sed nunc a diam scelerisque dignissim. In nec sapien ac ex imperdiet condimentum. Duis pulvinar elit eget tellus viverra facilisis. In at risus diam. Fusce ut urna ut enim laoreet fermentum. Nullam suscipit quam ac egestas tempus. Donec commodo arcu tempor odio sagittis eleifend. Nunc volutpat augue quis libero consequat, eu rhoncus nibh venenatis. Nullam scelerisque varius pellentesque.',
      requestedOn: '07-28-21',
      tentativeStartDate: '2021-08-12',
      orgOffice: { orgOfficeId: '100001', orgOfficeName: 'Austin Office' },
      orgPracticeGroup: { orgPracticeGroupId: '200001', orgPracticeGroupName: 'Corporate Law' },
      requestedBy: {
        userId: 5,
        userFullName: 'Max Hurran',
        userAvatar: require('@src/assets/images/avatars/10.png').default
      },
      coachees: [
        {
          userId: 5,
          userFullName: 'Max Hurran',
          userAvatar: require('@src/assets/images/avatars/10.png').default
        },
        {
          userId: 11,
          userFullName: 'Jonah Wharlton',
          userAvatar: require('@src/assets/images/avatars/4.png').default
        }
      ],
      coaches: [
        {
          coachId: 3,
          coachName: 'Alfreda McCray',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default
        },
        {
          coachId: 4,
          coachName: 'Neiman Hatcher',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default
        }
      ],
      selectedCoach: null,
      currentStatus: { statusId: 'pending', statusName: 'Pending' },
      statusHistory: [
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' }
      ],
      changeHistory: [
        {
          activityId: '20bea752-b0c7-49cc-8dc5-77b5fcf9c5a0',
          activityTitle: 'Engagement Request Modified',
          activityMeta: '2 day ago',
          activityContent: 'Add details to Engagement',
          activityColorClass: 'warning',
          activityCreatedBy: {
            userId: 5,
            userFullName: 'Max Hurran',
            userAvatar: require('@src/assets/images/avatars/10.png').default,
            userTitle: null
          },
          activityCreatedOn: '07/21/2021',
          attachment: null
        },
        {
          activityId: '7b81c7e5-a6de-43f0-9321-3e8eab85a854',
          activityTitle: 'Engagement Request Created',
          activityMeta: '3 day ago',
          activityContent: 'Created initial Engagement Request',
          activityColorClass: 'danger',
          activityCreatedBy: {
            userId: 5,
            userFullName: 'Max Hurran',
            userAvatar: require('@src/assets/images/avatars/10.png').default,
            userTitle: null
          },
          activityCreatedOn: '07/20/2021',
          attachment: null
        }
      ],
      experienceLevels: [{ id: 'CEL0004', experienceLevelName: 'Partner / Executive' }],
      modalities: [{ id: 'CM00002', modalityName: 'Virtual' }],
      instructionTypes: [
        { id: 'CI00001', instructionTypeName: '1 to 1' },
        { id: 'CI00002', instructionTypeName: 'Small Group (2 - 10)' }
      ],
      languages: [
        { id: 'English', languageName: 'English' },
        { id: 'French', languageName: 'French' },
        { id: 'Spanish', languageName: 'Spanish' }
      ],
      coachingSpecialties: [
        { coachingSpecialtyId: 'CS00008', coachingSpecialtyName: 'Imposter Syndrome' },
        { coachingSpecialtyId: 'CS00009', coachingSpecialtyName: 'Leadership' },
        { coachingSpecialtyId: 'CS00010', coachingSpecialtyName: 'LinkedIn' }
      ]
    },
    {
      id: 10015,
      orgId: 10000000,
      title: 'Donec vestibulum justo ullamcorper ipsum ultrices ultricies',
      description: 'Sed non volutpat ante. Vestibulum sit amet ligula feugiat, hendrerit odio sed, aliquet leo. Vivamus commodo risus et scelerisque facilisis. Donec sed sem vel turpis faucibus fringilla. Curabitur sollicitudin enim ligula, ac vulputate dolor porttitor non. Morbi convallis elit nec aliquam vulputate. Maecenas suscipit orci ac sem finibus, in condimentum felis gravida. Etiam risus eros, egestas at sollicitudin at, euismod malesuada felis. Aliquam vestibulum nisi et efficitur efficitur. Aliquam dui sapien, dictum vel condimentum iaculis, placerat non lorem. Sed viverra dictum ultricies. Pellentesque euismod rhoncus suscipit. Cras vitae sapien ac nisi congue congue sed vitae lacus.',
      requestedOn: '07-28-21',
      tentativeStartDate: '2021-08-12',
      orgOffice: { orgOfficeId: '100001', orgOfficeName: 'Austin Office' },
      orgPracticeGroup: { orgPracticeGroupId: '200001', orgPracticeGroupName: 'Corporate Law' },
      requestedBy: {
        userId: 1,
        userFullName: 'Galen Slixby',
        userAvatar: ''
      },
      coachees: [
        {
          userId: 1,
          userFullName: 'Galen Slixby',
          userAvatar: ''
        }
      ],
      coaches: [
        {
          coachId: 1,
          coachName: 'Bryce Hoffman',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default
        },
        {
          coachId: 2,
          coachName: 'Jenny Shiers',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default
        },
        {
          coachId: 3,
          coachName: 'Alfreda McCray',
          coachAvatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default
        }
      ],
      selectedCoach: null,
      currentStatus: { statusId: 'pending', statusName: 'Pending' },
      statusHistory: [
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' },
        { statusId: '', statusName: '', updatedBy: '', updatedOn: '' }
      ],
      changeHistory: [
        {
          activityId: '7b81c7e5-a6de-43f0-9321-3e8eab85a854',
          activityTitle: 'Engagement Request Created',
          activityMeta: '3 day ago',
          activityContent: 'Created initial Engagement Request',
          activityColorClass: 'danger',
          activityCreatedBy: {
            userId: 1,
            userFullName: 'Galen Slixby',
            userAvatar: '',
            userTitle: null
          },
          activityCreatedOn: '07/20/2021',
          attachment: null
        }
      ],
      experienceLevels: [{ id: 'CEL0001', experienceLevelName: 'Junior Associate' }],
      modalities: [{ id: 'CM00001', modalityName: 'In person' }],
      instructionTypes: [{ id: 'CI00005', instructionTypeName: 'Offsites / Retreats' }],
      languages: [{ id: 'English', languageName: 'English' }],
      coachingSpecialties: [
        { coachingSpecialtyId: 'CS00013', coachingSpecialtyName: 'Mindfulness' },
        { coachingSpecialtyId: 'CS00014', coachingSpecialtyName: 'Mindset' },
        { coachingSpecialtyId: 'CS00015', coachingSpecialtyName: 'Practice Management' }
      ]
    }
  ]
}

// {
//   coachId: 1,
//   coachName: 'Bryce Hoffman',
//   coachAvatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
// },
// {
//   coachId: 2,
//   coachName: 'Jenny Shiers',
//   coachAvatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
// },
// {
//   coachId: 3,
//   coachName: 'Alfreda McCray',
//   coachAvatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
// },
// {
//   coachId: 4,
//   coachName: 'Neiman Hatcher',
//   coachAvatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
// },
// {
//   coachId: 5,
//   coachName: 'Daniel Lucido',
//   coachAvatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
// },
// {
//   coachId: 6,
//   coachName: 'Laura Chavis',
//   coachAvatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default,
// },
// {
//   coachId: 7,
//   coachName: 'Jakoba Franzen',
//   coachAvatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
// },
// {
//   coachId: 8,
//   coachName: 'Andy Johnson',
//   coachAvatar: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
// },
// {
//   coachId: 9,
//   coachName: 'Scott Brown',
//   coachAvatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
// },
// {
//   coachId: 10,
//   coachName: 'Danielle Kasch',
//   coachAvatar: require('@src/assets/images/portrait/small/avatar-s-10.jpg').default,
// },
// {
//   coachId: 11,
//   coachName: 'Ruth Wilkinson',
//   coachAvatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
// }


// Users
// {
//   userId: 1,
//   userFullName: 'Galen Slixby',
//   userAvatar: ''
// },
// {
//   userId: 2,
//   userFullName: 'Halsey Redmore',
//   userAvatar: require('@src/assets/images/avatars/10.png').default,
// },
// {
//   userId: 3,
//   userFullName: 'Marjory Sicely',
//   userAvatar: require('@src/assets/images/avatars/1.png').default,
// },
// {
//   userId: 4,
//   userFullName: 'Cyrill Risby',
//   userAvatar: require('@src/assets/images/avatars/9.png').default,
// },
// {
//   userId: 5,
//   userFullName: 'Max Hurran',
//   userAvatar: require('@src/assets/images/avatars/10.png').default,
// },
// {
//   userId: 6,
//   userFullName: 'Silvain Halstead',
//   userAvatar: '',
// },
// {
//   userId: 7,
//   userFullName: 'Breena Gallemore',
//   userAvatar: '',
// },
// {
//   userId: 8,
//   userFullName: 'Kathryne Liger',
//   userAvatar: require('@src/assets/images/avatars/9.png').default,
// },
// {
//   userId: 9,
//   userFullName: "Felix Scotfurth",
//   userAvatar: require('@src/assets/images/avatars/2.png').default,
// },
// {
//   userId: 10,
//   userFullName: 'Jillene Bellany',
//   userAvatar: require('@src/assets/images/avatars/9.png').default,
// },
// {
//   userId: 11,
//   userFullName: 'Jonah Wharlton',
//   userAvatar: require('@src/assets/images/avatars/4.png').default,
// }


// GET ALL DATA
// mock.onGet('/api/myorganization/users').reply(200, data.users)


mock.onGet('/api/myorganization').reply(() => {
  const myFilteredOrg = data.organizations.find(i => i.orgId === 10000000)
  return [200, myFilteredOrg]
})


// GET Updated DATA
mock.onGet('/api/myorganization/users').reply(config => {
  const { q = '', perPage = 10, page = 1,
    role = null,
    experienceLevel = null,
    office = null,
    status = null
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = data.users.filter(
    user =>
      user.fullName.toLowerCase().includes(queryLowered)
      //user.role === (role || user.role) &&
      && user.office.value === (office || user.office.value)
      && user.experienceLevel.value === (experienceLevel || user.experienceLevel.value)
      && user.engagementStatus.value === (status || user.engagementStatus.value)
  )
  /* eslint-enable  */

  return [
    200,
    {
      users: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// POST: Add new user
mock.onPost('/api/myorganization/users/add-user').reply(config => {
  // Get event from post data  
  const user = JSON.parse(config.data)

  const { length } = data.users
  let lastIndex = 0
  if (length) {
    lastIndex = data.users[length - 1].id
  }

  //DATA TO BE PULLED FROM JWT IN BACKEND
  const mockCurrentCompanyName = 'Covington & Burling, LLP'
  const mockCurrentCompanyId = 10000000

  const organizationData = data.organizations.find(i => i.orgId === mockCurrentCompanyId)
  const organizationDropdowns = { ...organizationData.customDropdowns }

  const selectedExperienceLevelLabel = DefaultDropdownOptions.experienceLevelOptions.find(i => i.value === `${user.userExperienceLevel}`)?.label
  const selectedLocationLabel = DefaultDropdownOptions.userLocationOptions.find(i => i.value === `${user.userLocation}`)?.label
  const selectedRoleLabel = DefaultDropdownOptions.userRoleOptions.find(i => i.value === `${user.userRole}`)?.label
  const selectedUserPronounsLabel = DefaultDropdownOptions.pronounOptions.find(i => i.value === `${user.userPronouns}`)?.label

  const selectedPracticeGroupLabel = organizationDropdowns.orgPracticeGroupOptions.find(i => i.value === `${user.userPracticeGroup}`)?.label
  const selectedOfficeLabel = organizationDropdowns.orgOfficeOptions.find(i => i.value === `${user.userOffice}`)?.label

  const newUser = {
    id: lastIndex + 1,
    firstName: user.firstName,
    lastName: user.lastName,
    fullName: `${user.firstName} ${user.lastName}`,
    orgName: mockCurrentCompanyName,
    orgId: mockCurrentCompanyId,
    experienceLevel: { value: user.userExperienceLevel, label: selectedExperienceLevelLabel },
    practiceGroup: { value: user.userPracticeGroup, label: selectedPracticeGroupLabel },
    office: { value: user.userOffice, label: selectedOfficeLabel },
    engagementStatus: { value: 'notengaged', label: 'Not Engaged' },
    //location: { value: user.userLocation, label: 'Unknown' },
    location: { value: user.userLocation, label: selectedLocationLabel },
    email: user.email,
    avatar: '',
    role: { value: user.userRole, label: selectedRoleLabel },
    pronouns: { value: user.userPronouns, label: selectedUserPronounsLabel }
  }

  data.users.unshift(newUser)

  return [201, { newUser }]
})

// POST: EDIT USER PROFILE
mock.onPost('/api/myorganization/users/edit-user-account').reply(config => {

  // Get event from post data  
  const userAccountEditedValues = JSON.parse(config.data)

  const userToBeEdited = data.users.find(i => i.id === userAccountEditedValues.id)

  if (userToBeEdited === null || userToBeEdited === undefined) return [404, {}]

  userToBeEdited.firstName = userAccountEditedValues.firstName
  userToBeEdited.lastName = userAccountEditedValues.lastName
  userToBeEdited.fullName = `${userAccountEditedValues.firstName} ${userAccountEditedValues.lastName}`
  userToBeEdited.email = userAccountEditedValues.email
  userToBeEdited.pronouns = { value: userAccountEditedValues.userPronouns.value, label: userAccountEditedValues.userPronouns.label }
  userToBeEdited.role = { value: userAccountEditedValues.userRole.value, label: userAccountEditedValues.userRole.label }

  //console.log('finalEditedUser', userToBeEdited)
  return [200, { editedUser: userToBeEdited }]
})

mock.onPost('/api/myorganization/users/edit-user-profile').reply(config => {

  // Get event from post data  
  const userProfileEditedValues = JSON.parse(config.data)

  const userToBeEdited = data.users.find(i => i.id === userProfileEditedValues.id)

  if (userToBeEdited === null || userToBeEdited === undefined) return [404, {}]

  //console.log('USER TO BE EDITED DETAILS', userToBeEdited)

  userToBeEdited.experienceLevel = { value: userProfileEditedValues.userExperienceLevel.value, label: userProfileEditedValues.userExperienceLevel.label }
  userToBeEdited.location = { value: userProfileEditedValues.userLocation.value, label: userProfileEditedValues.userLocation.label }
  userToBeEdited.office = { value: userProfileEditedValues.userOffice.value, label: userProfileEditedValues.userOffice.label }
  userToBeEdited.practiceGroup = { value: userProfileEditedValues.userPracticeGroup.value, label: userProfileEditedValues.userPracticeGroup.label }

  //console.log('finalEditedUser', userToBeEdited)
  return [200, { editedUser: userToBeEdited }]
})

// // GET USER
mock.onGet('/api/myorganization/user').reply(config => {
  const { id } = config
  //console.log('CALLING /api/users/user with params:', config)
  const user = data.users.find(i => i.id === id)
  return [200, { user }]
})

// // DELETE: Deletes User
// mock.onDelete('/apps/users/delete').reply(config => {
//   // Get user id from URL
//   let userId = config.id

//   // Convert Id to number
//   userId = Number(userId)

//   const userIndex = data.users.findIndex(t => t.id === userId)
//   data.users.splice(userIndex, 1)

//   return [200]
// })

// ------------------------------------------------
// GET: Return Engagement Requests List For Company
// ------------------------------------------------
mock.onGet('/engagements/findengagements').reply(config => {
  const { q = '', perPage = 10, page = 1, selectedStatus = null } = config.params

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = data.userEngagements.filter(
    engagement =>
      (engagement.title.toLowerCase().includes(queryLowered) ||
        engagement.coachees.filter(
          coachee => coachee.userFullName.toLowerCase().includes(queryLowered)
        ).length)
      && engagement.currentStatus.statusId === (selectedStatus || engagement.currentStatus.statusId)
  ).sort(sortCompare('id')).reverse()

  /* eslint-enable  */
  return [
    200,
    {
      success: true,
      data: {
        recordsMetadata: { totalRecords: filteredData.length },
        engagements: paginateArray(filteredData, perPage, page)
      }
    }
  ]
})


// ------------------------------------------------------
// GET: Return Engagement Request Data by Id For Company
// ------------------------------------------------------

mock.onGet('/engagements').reply(config => {
  const { id } = config.params

  const engagement = data.userEngagements.find(i => i.id === Number(id))
  //console.log('true engagements', engagement)
  return [
    200, {
      success: true,
      data: {
        engagementDetails: engagement
      }
    }
  ]
})

// ------------------------------------------------------
// POST: Add new Engagement Request
// ------------------------------------------------------
mock.onPost('/api/engagements/request/add-engagement').reply(config => {

  //console.log('INVOKED API: /api/engagements/request/add-engagement', config)
  const params = JSON.parse(config.data)

  // ** CALCULATING NEXT ENGAGEMENT ID ... 
  const { length } = data.userEngagements
  let lastIndex = 0
  if (length) {
    lastIndex = data.userEngagements[length - 1].id
  }
  const newEngagementId = (lastIndex + 1)

  // ** ON REAL API, THE FOLLOWING PARAMS NEED TO BE RETRIEVED FROM THE TOKEN
  const defaultOrgId = 10000000
  const defaultUserData = {
    userId: 1,
    userFullName: 'Galen Slixby',
    userAvatar: '',
    userTitle: null
  }

  // ** Mocking Coachee List Creation
  const { coachees } = params
  const coacheeList = coachees.map(item => {
    const coachee = {}
    coachee.userId = item.value * 1
    coachee.userFullName = item.label
    coachee.userAvatar = item.avatar
    return coachee
  })

  // ** Mocking Coaches List Creation
  const { coaches } = params
  const coachList = coaches.map(item => {
    const coach = {}
    coach.coachId = item.value
    coach.coachName = item.label
    coach.coachAvatar = item.avatar
    return coach
  })

  // ** Mocking Experience Levels List Creation
  const { experienceLevels } = params
  const experienceLevelList = experienceLevels.map(item => {
    const experienceLevel = {}
    experienceLevel.id = item.value
    experienceLevel.experienceLevelName = item.label
    return experienceLevel
  })

  // ** Mocking Modalities List Creation
  const { modalities } = params
  const modalityList = modalities.map(item => {
    const modality = {}
    modality.id = item.value
    modality.modalityName = item.label
    return modality
  })

  // ** Mocking Instruction Types List Creation
  const { instructionTypes } = params
  const instructionTypeList = instructionTypes.map(item => {
    const instructionType = {}
    instructionType.id = item.value
    instructionType.instructionTypeName = item.label
    return instructionType
  })

  // ** Mocking Languages List Creation
  const { languages } = params
  const languageList = languages.map(item => {
    const language = {}
    language.id = item.value
    language.languageName = item.label
    return language
  })

  // ** Mocking Coaching Specialties List Creation
  const { coachingSpecialties } = params
  const coachingSpecialtyList = coachingSpecialties.map(item => {
    const coachingSpecialty = {}
    coachingSpecialty.coachingSpecialtyId = item.value
    coachingSpecialty.coachingSpecialtyName = item.label
    return coachingSpecialty
  })

  const requestDate = moment().format('YYYY-MM-DD')
  const engagement = {
    id: newEngagementId,
    orgId: defaultOrgId,
    title: params.title,
    description: params.description,
    requestedOn: requestDate,
    tentativeStartDate: params.dueDate,
    orgOffice: { orgOfficeId: params.orgOffice.value, orgOfficeName: params.orgOffice.label },
    orgPracticeGroup: { orgPracticeGroupId: params.orgPracticeGroup.value, orgPracticeGroupName: params.orgPracticeGroup.label },
    requestedBy: defaultUserData,
    coachees: coacheeList,
    coaches: coachList,
    selectedCoach: null,
    currentStatus: { statusId: 'pending', statusName: 'Pending' },
    statusHistory: [{ statusId: 'pending', statusName: 'Pending', updatedBy: defaultUserData.userId, updatedOn: requestDate }],
    changeHistory: [
      {
        activityId: '7b81c7e5-a6de-43f0-9321-3e8eab85a854',
        activityTitle: 'Engagement Request Created',
        activityMeta: 'A few min ago',
        activityContent: 'Created initial Engagement Request',
        activityColorClass: 'danger',
        activityCreatedBy: defaultUserData,
        activityCreatedOn: requestDate, //change for datetime NOW!!
        attachment: null
      }
    ],
    experienceLevels: experienceLevelList,
    modalities: modalityList,
    instructionTypes: instructionTypeList,
    languages: languageList,
    coachingSpecialties: coachingSpecialtyList
  }

  //console.log(engagement)

  data.userEngagements.push(engagement)

  return [201, { engagement }]
})

// ------------------------------------------------
// GET: Return User Engagements List
// ------------------------------------------------
mock.onGet('/api/myorganization/user/engagements').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, status = null, userId } = config
  /* eslint-enable */

  //console.log('CALLING /api/myorganization/user/engagements with the following params', config)

  const queryLowered = q.toLowerCase()
  const filteredData = data.userEngagements
    .filter(
      userEngagements =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (
        (userEngagements.title.toLowerCase().includes(queryLowered) ||
          userEngagements.coaches.filter(
            coach => coach.coachName.toLowerCase().includes(queryLowered)
          ).length)
        && userEngagements.currentStatus.statusId === (status || userEngagements.currentStatus.statusId)
        && (userEngagements.requestedBy.userId.toString() === userId ||
          userEngagements.coachees.filter(c => c.userId.toString() === userId)?.length)
      )
    )
    .sort(sortCompare('id'))
    .reverse()
  /* eslint-enable  */

  return [
    200,
    {
      allData: null,
      userEngagements: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})