// ** Initial State
const initialState = {
  params: {},
  selectedRequest: {},
  trainings: [],
  trainingDetail: {},
  totalTrainings: 0,
  isLoading: true
}

const training = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FIND_A_TRAINING_LOADING_STATUS':
      return { ...state, isLoading: action.data }
    case 'GET_TRAININGS':
      return {
        ...state,
        trainings: action.data.data.trainingList,
        params: action.params,
        totalTrainings: action.data.data.recordsMetadata.totalRecords,
        trainingDetail: {},
        isLoading: false
      }
    case 'GET_TRAINING_DETAILS':
      return {
        ...state, trainingDetail: action.trainingDetails
      }
    case 'TRAINING_CLEAR_SELECTED_TRAINING':
      return { ...state, trainingDetail: {} }
    case 'GET_ALL_TRAININGS':
      return {
        ...state,
        trainings: action.data.data.trainingList,
        params: action.params,
        totalTrainings: action.data.data.recordsMetadata.totalRecords,
        trainingDetail: {},
        isLoading: false
      }
    default:
      return { ...state }
  }
}
export default training
