import mock from '../mock'
import { paginateArray } from '../utils'

const data = {
  engagements: [
    {
      id: 10001,
      user: 'Galen Slixby',
      coachee: 'Halsey Redmore',
      selectedCoachees: [
        {
          coacheeId: '',
          coacheeName: ''
        },
        {
          coacheeId: '',
          coacheeName: ''
        },
        {
          coacheeId: '',
          coacheeName: ''
        }
      ],
      company: 'Yotz PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(479) 232-9151',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'completed',
      coachingSpecialties: [
        'Conflict resolution',
        'Diversity, Equity and Inclusion'
      ],
      instructionType: ['1 to 1'],
      experienceLevel: [
        'Junior associates',
        'Partners / executives'
      ],
      modality: 'Virtual',
      languages: 'English',
      practiceArea: 'practice area',
      office: 'Office',
      avatar: ''
    },
    {
      id: 10002,
      user: 'Halsey Redmore',
      coachee: 'Galen Slixby',
      company: 'Skinder PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(472) 607-9137',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'pending',
      coachingSpecialties: [
        'Making Partner',
        'Management',
        'Mindfulness',
        'Women’s Empowerment '
      ],
      instructionType: ['1 to 1'],
      experienceLevel: [
        'Junior associates',
        'Partners / executives'
      ],
      modality: 'Virtual',
      languages: 'English',
      practiceArea: 'practice area',
      office: 'Office',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      id: 10003,
      user: 'Marjory Sicely',
      coachee: 'Galen Slixby',
      company: 'Oozz PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(321) 264-4599',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'active',
      coachingSpecialties: [
        'Conflict resolution',
        'Diversity, Equity and Inclusion',
        'LinkedIn',
        'Making Partner',
        'Management',
        'Mindfulness',
        'Women’s Empowerment '
      ],
      instructionType: ['1 to 1'],
      experienceLevel: [
        'Junior associates',
        'Partners / executives'
      ],
      modality: 'Virtual',
      languages: 'English',
      practiceArea: 'practice area',
      office: 'Office',
      avatar: require('@src/assets/images/avatars/1.png').default
    },
    {
      id: 10004,
      user: 'Cyrill Risby',
      coachee: 'Halsey Redmore',
      company: 'Oozz PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(923) 690-6806',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'completed',
      coachingSpecialties: [
        'Conflict resolution',
        'Diversity, Equity and Inclusion',
        'LinkedIn',
        'Making Partner',
        'Management',
        'Mindfulness',
        'Women’s Empowerment '
      ],
      instructionType: ['1 to 1'],
      experienceLevel: [
        'Junior associates',
        'Partners / executives'
      ],
      modality: 'Virtual',
      languages: 'English',
      practiceArea: 'practice area',
      office: 'Office',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      id: 10005,
      user: 'Maggy Hurran',
      coachee: 'Halsey Redmore',
      company: 'Aimbo PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(669) 914-1078',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'pending',
      coachingSpecialties: [
        'Conflict resolution',
        'Diversity, Equity and Inclusion',
        'LinkedIn',
        'Making Partner',
        'Management',
        'Mindfulness',
        'Women’s Empowerment '
      ],
      instructionType: ['1 to 1'],
      experienceLevel: [
        'Junior associates',
        'Partners / executives'
      ],
      modality: 'Virtual',
      languages: 'English',
      practiceArea: 'practice area',
      office: 'Office',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      id: 10006,
      user: 'Silvain Halstead',
      coachee: 'Halsey Redmore',
      company: 'Jaxbean PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(958) 973-3093',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'active',
      coachingSpecialties: [
        'Conflict resolution',
        'Diversity, Equity and Inclusion',
        'LinkedIn',
        'Making Partner',
        'Management',
        'Mindfulness',
        'Women’s Empowerment '
      ],
      instructionType: ['1 to 1'],
      experienceLevel: [
        'Junior associates',
        'Partners / executives'
      ],
      modality: 'Virtual',
      languages: 'English',
      practiceArea: 'practice area',
      office: 'Office',
      avatar: ''
    },
    {
      id: 10007,
      user: 'Breena Gallemore',
      coachee: 'Halsey Redmore',
      company: 'Jazzy PVT LTD',
      startDate: '17 Jul 2019',
      contact: '(825) 977-8152',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'pending',
      coachingSpecialties: [
        'Conflict resolution',
        'Diversity, Equity and Inclusion',
        'LinkedIn',
        'Making Partner',
        'Management',
        'Mindfulness',
        'Women’s Empowerment '
      ],
      instructionType: ['1 to 1'],
      experienceLevel: [
        'Junior associates',
        'Partners / executives'
      ],
      modality: 'Virtual',
      languages: 'English',
      practiceArea: 'practice area',
      office: 'Office',
      avatar: ''
    },
    {
      id: 10008,
      user: 'Kathryne Liger',
      coachee: 'Halsey Redmore',
      company: 'Pixoboo PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(187) 440-0934',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'pending',
      coachingSpecialties: [
        'Conflict resolution',
        'Diversity, Equity and Inclusion',
        'LinkedIn',
        'Making Partner',
        'Management',
        'Mindfulness',
        'Women’s Empowerment '
      ],
      instructionType: ['1 to 1'],
      experienceLevel: [
        'Junior associates',
        'Partners / executives'
      ],
      modality: 'Virtual',
      languages: 'English',
      practiceArea: 'practice area',
      office: 'Office',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      id: 10009,
      user: 'Franz Scotfurth',
      coachee: 'Kathryne Liger',
      company: 'Tekfly PVT LTD',
      startDate: '17 Jul 2019',
      contact: '(978) 146-5443',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'pending',
      coachingSpecialties: [
        'Conflict resolution',
        'Diversity, Equity and Inclusion',
        'LinkedIn',
        'Making Partner',
        'Management',
        'Mindfulness',
        'Women’s Empowerment '
      ],
      instructionType: ['1 to 1'],
      experienceLevel: [
        'Junior associates',
        'Partners / executives'
      ],
      modality: 'Virtual',
      languages: 'English',
      practiceArea: 'practice area',
      office: 'Office',
      avatar: require('@src/assets/images/avatars/2.png').default
    },
    {
      id: 10010,
      user: 'Jillene Bellany',
      coachee: 'Kathryne Liger',
      company: 'Gigashots PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(589) 284-6732',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'completed',
      coachingSpecialties: [
        'Conflict resolution',
        'Diversity, Equity and Inclusion',
        'LinkedIn',
        'Making Partner',
        'Management',
        'Mindfulness',
        'Women’s Empowerment '
      ],
      instructionType: ['1 to 1'],
      experienceLevel: [
        'Junior associates',
        'Partners / executives'
      ],
      modality: 'Virtual',
      languages: 'English',
      practiceArea: 'practice area',
      office: 'Office',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      id: 10011,
      user: 'Jonah Wharlton',
      coachee: 'Kathryne Liger',
      company: 'Eare PVT LTD',
      startDate: '17 Jul 2019',
      contact: '(176) 532-6824',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'completed',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/4.png').default
    },
    {
      id: 10012,
      user: 'Seth Hallam',
      company: 'Yakitri PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(234) 464-0600',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'pending',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/5.png').default
    },
    {
      id: 10013,
      user: 'Yoko Pottie',
      coachee: 'Kathryne Liger',
      company: 'Leenti PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(907) 284-5083',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'completed',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/7.png').default
    },
    {
      id: 10014,
      user: 'Maximilianus Krause',
      coachee: 'Kathryne Liger',
      company: 'Digitube PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(167) 135-7392',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'active',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      id: 10015,
      user: 'Zsazsa McCleverty',
      coachee: 'Kathryne Liger',
      company: 'Kaymbo PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(317) 409-6565',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'active',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/2.png').default
    },
    {
      id: 10016,
      user: 'Bentlee Emblin',
      coachee: 'Kathryne Liger',
      company: 'Yambee PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(590) 606-1056',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'active',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/6.png').default
    },
    {
      id: 10017,
      user: 'Brockie Myles',
      company: 'Wikivu PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(553) 225-9905',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'active',
      modality: 'Virtual',
      languages: 'English',
      avatar: ''
    },
    {
      id: 10018,
      user: 'Bertha Biner',
      company: 'Twinte PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(901) 916-9287',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'active',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/7.png').default
    },
    {
      id: 10019,
      user: 'Travus Bruntjen',
      coachee: 'Kathryne Liger',
      company: 'Cogidoo PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(524) 586-6057',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'active',
      modality: 'Virtual',
      languages: 'English',
      avatar: ''
    },
    {
      id: 10020,
      user: 'Wesley Burland',
      coachee: 'Kathryne Liger',
      company: 'Bubblemix PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(569) 683-1292',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'completed',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/6.png').default
    },
    {
      id: 21,
      user: 'Selina Kyle',
      coachee: 'Kathryne Liger',
      company: 'Wayne Enterprises',
      startDate: '13 Dec 2019',
      contact: '(829) 537-0057',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'active',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/1.png').default
    },
    {
      id: 22,
      user: 'Jameson Lyster',
      coachee: 'Kathryne Liger',
      company: 'Quaxo PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(593) 624-0222',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'completed',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/8.png').default
    },
    {
      id: 23,
      user: 'Kare Skitterel',
      coachee: 'Kathryne Liger',
      company: 'Ainyx PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(254) 845-4107',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'pending',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/3.png').default
    },
    {
      id: 24,
      user: 'Cleavland Hatherleigh',
      coachee: 'Kare Skitterel',
      company: 'Flipopia PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(700) 783-7498',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'pending',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/2.png').default
    },
    {
      id: 25,
      user: 'Adeline Micco',
      coachee: 'Kare Skitterel',
      company: 'Topicware PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(227) 598-1841',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'pending',
      modality: 'Virtual',
      languages: 'English',
      avatar: ''
    },
    {
      id: 26,
      user: 'Hugh Hasson',
      coachee: 'Kare Skitterel',
      company: 'Skinix PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(582) 516-1324',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'completed',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/4.png').default
    },
    {
      id: 27,
      user: 'Germain Jacombs',
      coachee: 'Kare Skitterel',
      company: 'Youopia PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(137) 467-5393',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'active',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      id: 28,
      user: 'Bree Kilday',
      coachee: 'Kare Skitterel',
      company: 'Jetpulse PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(412) 476-0854',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'active',
      modality: 'Virtual',
      languages: 'English',
      avatar: ''
    },
    {
      id: 29,
      user: 'Candice Pinyon',
      coachee: 'Kare Skitterel',
      company: 'Kare PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(170) 683-1520',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'active',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/7.png').default
    },
    {
      id: 30,
      user: 'Isabel Mallindine',
      coachee: 'Kare Skitterel',
      company: 'Voomm PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(332) 803-1983',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'pending',
      modality: 'Virtual',
      languages: 'English',
      avatar: ''
    },
    {
      id: 31,
      user: 'Gwendolyn Meineken',
      coachee: 'Kare Skitterel',
      company: 'Oyondu PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(551) 379-7460',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'pending',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/1.png').default
    },
    {
      id: 32,
      user: 'Rafaellle Snowball',
      coachee: 'Kare Skitterel',
      company: 'Fivespan PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(974) 829-0911',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'pending',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/5.png').default
    },
    {
      id: 33,
      user: 'Rochette Emer',
      coachee: 'Kare Skitterel',
      company: 'Thoughtworks PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(841) 889-3339',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'active',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/8.png').default
    },
    {
      id: 34,
      user: 'Ophelie Fibbens',
      coachee: 'Kare Skitterel',
      company: 'Jaxbean PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(764) 885-7351',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'active',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/4.png').default
    },
    {
      id: 35,
      user: 'Stephen MacGilfoyle',
      coachee: 'Kare Skitterel',
      company: 'Browseblab PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(350) 589-8520',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'pending',
      modality: 'Virtual',
      languages: 'English',
      avatar: ''
    },
    {
      id: 36,
      user: 'Bradan Rosebotham',
      coachee: 'Kare Skitterel',
      company: 'Agivu PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(882) 933-2180',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'completed',
      modality: 'Virtual',
      languages: 'English',
      avatar: ''
    },
    {
      id: 37,
      user: 'Skip Hebblethwaite',
      coachee: 'Bradan Rosebotham',
      company: 'Katz PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(610) 343-1024',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'completed',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      id: 38,
      user: 'Moritz Piccard',
      coachee: 'Bradan Rosebotham',
      company: 'Twitternation PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(365) 277-2986',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'completed',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/1.png').default
    },
    {
      id: 39,
      user: 'Tyne Widmore',
      coachee: 'Bradan Rosebotham',
      company: 'Yombu PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(531) 731-0928',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'pending',
      modality: 'Virtual',
      languages: 'English',
      avatar: ''
    },
    {
      id: 40,
      user: 'Florenza Desporte',
      coachee: 'Bradan Rosebotham',
      company: 'Kamba PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(312) 104-2638',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'active',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/6.png').default
    },
    {
      id: 41,
      user: 'Edwina Baldetti',
      coachee: 'Bradan Rosebotham',
      company: 'Dazzlesphere PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(315) 329-3578',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'pending',
      modality: 'Virtual',
      languages: 'English',
      avatar: ''
    },
    {
      id: 42,
      user: 'Benedetto Rossiter',
      coachee: 'Bradan Rosebotham',
      company: 'Mybuzz PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(323) 175-6741',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'completed',
      modality: 'Virtual',
      languages: 'English',
      avatar: ''
    },
    {
      id: 43,
      user: 'Micaela McNirlan',
      coachee: 'Bradan Rosebotham',
      company: 'Tambee PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(242) 952-0916',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'completed',
      modality: 'Virtual',
      languages: 'English',
      avatar: ''
    },
    {
      id: 44,
      user: 'Vladamir Koschek',
      coachee: 'Bradan Rosebotham',
      company: 'Centimia PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(531) 758-8335',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'active',
      modality: 'Virtual',
      languages: 'English',
      avatar: ''
    },
    {
      id: 45,
      user: 'Corrie Perot',
      coachee: 'Bradan Rosebotham',
      company: 'Flipopia PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(659) 385-6808',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'pending',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/3.png').default
    },
    {
      id: 46,
      user: 'Saunder Offner',
      coachee: 'Bradan Rosebotham',
      company: 'Skalith PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(200) 586-2264',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'pending',
      modality: 'Virtual',
      languages: 'English',
      avatar: ''
    },
    {
      id: 47,
      user: 'Karena Courtliff',
      coachee: 'Bradan Rosebotham',
      company: 'Feedfire PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(478) 199-0020',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'active',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/1.png').default
    },
    {
      id: 48,
      user: 'Onfre Wind',
      coachee: 'Bradan Rosebotham',
      company: 'Thoughtmix PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(344) 262-7270',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'pending',
      modality: 'Virtual',
      languages: 'English',
      avatar: ''
    },
    {
      id: 49,
      user: 'Paulie Durber',
      coachee: 'Bradan Rosebotham',
      company: 'Babbleblab PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(694) 676-1275',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'completed',
      modality: 'Virtual',
      languages: 'English',
      avatar: ''
    },
    {
      id: 50,
      user: 'Beverlie Krabbe',
      coachee: 'Bradan Rosebotham',
      company: 'Kaymbo PVT LTD',
      startDate: '13 Dec 2019',
      contact: '(397) 294-5153',
      title: 'engagement test',
      description: 'help others to find meaning, autonomy, grow, impact and connection in their jobs',
      status: 'active',
      modality: 'Virtual',
      languages: 'English',
      avatar: require('@src/assets/images/avatars/9.png').default
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/engagements/list/all-data').reply(200, data.engagements)

// POST: Add new user
mock.onPost('/api/engagements/add-engagement').reply(config => {
  // Get event from post data
  const engagement = JSON.parse(config.data)

  const { length } = data.engagements
  let lastIndex = 0
  if (length) {
    lastIndex = data.engagements[length - 1].id
  }
  engagement.id = lastIndex + 1

  data.engagements.unshift(engagement)

  return [201, { engagement }]
})

// GET Updated DATA
mock.onGet('/api/engagements/list/data').reply(config => {
  const { q = '', perPage = 10, page = 1, status = null } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = data.engagements.filter(
    engagement =>
      (engagement.user.toLowerCase().includes(queryLowered) || engagement.coachee.toLowerCase().includes(queryLowered)) &&
      engagement.status === (status || engagement.status)
  )
  /* eslint-enable  */

  return [
    200,
    {
      engagements: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET USER
mock.onGet('/api/engagements/engagement').reply(config => {
  const { id } = config
  const engagement = data.engagements.find(i => i.id === id)
  return [200, { engagement }]
})

// DELETE: Deletes User
mock.onDelete('/api/engagements/delete').reply(config => {
  // Get user id from URL
  let userId = config.id

  // Convert Id to number
  userId = Number(userId)

  const userIndex = data.engagements.findIndex(t => t.id === userId)
  data.engagements.splice(userIndex, 1)

  return [200]
})
